import React from 'react';
import classnames from 'classnames/bind';
import { Link } from 'react-router-dom';

import Typography from '@material-ui/core/Typography';
import styles from './notFoundPage.module.scss';
import notFoundGIF from '../../../assets/images/not-found.gif';

const cx = classnames.bind(styles);

function NotFoundPage() {
  return (
    <div className={cx('container')}>
      <div className={cx('row')}>
        <div className={cx('col-12')}>
          <div className={cx('error-wrapper')}>
            <h1 className={cx('error-wrapper__title', 'heading-2')}>Page Not Found</h1>
            <Link to="/" className={cx('error-wrapper__link')}>
              <Typography
                className={cx('text-center')}
                variant="h6"
                color="textPrimary"
                component="p"
                gutterBottom
              >
                Go to Home
              </Typography>
            </Link>
            <div className={cx('error-wrapper__image-box')}>
              <img
                src={notFoundGIF}
                className={cx('error-wrapper__image')}
                alt="Not Found"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NotFoundPage;
