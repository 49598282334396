import React from 'react';
import classnames from 'classnames/bind';
import { workExperience } from '../../utils';
import MediaCard from '../../components/Cards/MediaCard';
import styles from './workExperiencePage.module.scss';
import HeaderBanner from '../../components/Banner/HeaderBanner';

const cx = classnames.bind(styles);

function WorkExperiencePage() {
  const workExperienceKeys = Object.keys(workExperience);

  return (
    <>
      <HeaderBanner title="Work Experience" />

      <div className={cx('container', 'gutter')}>
        <div className={cx('row', 'row-eq-height')}>
          {workExperienceKeys.map((key) => (
            <div
              key={workExperience[key].title}
              className={cx('col-md-4', 'col-12')}
            >
              <MediaCard
                title={workExperience[key].title}
                date={workExperience[key].date}
                description={workExperience[key].description}
                content={workExperience[key].content}
                imageURL={workExperience[key].imageURL}
                link={workExperience[key].link}
              />
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default WorkExperiencePage;
