import rafHackathon2019 from './assets/images/raf-hackathon-2019.jpg';
import aibg2019 from './assets/images/aibg-2019.jpeg';
import ibmDatathon2019 from './assets/images/ibm-datathon-2019.jpg';
import codeAI2019 from './assets/images/codeai-2019.jpg';
import fonHackathon2019 from './assets/images/fon-hackathon-2019.jpg';
import sicefHackathon2018 from './assets/images/sicef-hackathon-2018.jpg';
import nasaSpaceAppsChallenge2018 from './assets/images/nasa-hackathon-2018.jpg';
import codeGovernment2018 from './assets/images/code-government-hackathon-2018.jpg';
import matHack2018 from './assets/images/mathack-2018.jpg';
import fonHackathon2018 from './assets/images/fon-hackathon-2018.jpg';

import gridDynamicsImg from './assets/images/work-experience/grid_dynamics.png';
import rafImg from './assets/images/work-experience/raf.jpg';
import flockImg from './assets/images/work-experience/flock.jpeg';
import telekomImg from './assets/images/work-experience/telekom.jpg';
import startupImg from './assets/images/work-experience/startup.jpeg';
import devanaImg from './assets/images/work-experience/devana.png';
import tenderlyImg from './assets/images/work-experience/tenderly.svg';

import awsCertifiedCloudPractitionerImg from './assets/images/certificates/aws-certified-cloud-practitioner.png';

import jobertyImg from './assets/images/partners/joberty.png';
import netokracijaImg from './assets/images/partners/netokracija.png';
// import watfoiImg from './assets/images/partners/WATFOI.png';
import suftnImg from './assets/images/partners/SUFTN.png';

import firstToTheMoon2021Img from './assets/images/1st_to_the_moon_2021_landscape.png';

import aleksaCertImg from './assets/images/contests/first-to-the-moon-2021/certificates/TO-THE-MOON-certificate-Aleksa-Crveni.png';
// eslint-disable-next-line import/no-unresolved
import daniloCertImg from './assets/images/contests/first-to-the-moon-2021/certificates/TO-THE-MOON-certificate-Danilo-Mandušić.png';
// eslint-disable-next-line import/no-unresolved
import dejanCertImg from './assets/images/contests/first-to-the-moon-2021/certificates/TO-THE-MOON-certificate-Dejan-Bojović.png';
// eslint-disable-next-line import/no-unresolved
import mihailoCertImg from './assets/images/contests/first-to-the-moon-2021/certificates/TO-THE-MOON-certificate-Mihailo-Marčetić.png';
// eslint-disable-next-line import/no-unresolved
import milosIlicCertImg from './assets/images/contests/first-to-the-moon-2021/certificates/TO-THE-MOON-certificate-Miloš-Ilić.png';
// eslint-disable-next-line import/no-unresolved
import milosKozovicCertImg from './assets/images/contests/first-to-the-moon-2021/certificates/TO-THE-MOON-certificate-Miloš-Kožović.png';
// eslint-disable-next-line import/no-unresolved
import petarCertImg from './assets/images/contests/first-to-the-moon-2021/certificates/TO-THE-MOON-certificate-Petar-Kovačević.png';
// eslint-disable-next-line import/no-unresolved
import radojeCertImg from './assets/images/contests/first-to-the-moon-2021/certificates/TO-THE-MOON-certificate-Radoje-Jezdić.png';
import savaCertImg from './assets/images/contests/first-to-the-moon-2021/certificates/TO-THE-MOON-certificate-Sava-Nakarada.png';

export const workExperience = {
  tenderly: {
    title: 'Tenderly',
    date: 'April 2021 to Present',
    content: [{
      position: 'Frontend Engineer',
      description: [
        'Working on a UI part of the tool for real-time monitoring, alerting, and troubleshooting for Smart Contracts.',
        'Developing platform features in coordination with other engineers and managers.',
        'Troubleshooting and fixing software bugs.',
        'Implementing security and data protection policies.',
        'Proactively suggesting improvements (to existing features, code, and system architecture).',
        'Staying up-to-date on emerging technologies relevant to the industry.',
      ],
    }],
    imageURL: tenderlyImg,
    link: 'https://tenderly.co',
  },
  gridDynamics: {
    title: 'Grid Dynamics',
    date: 'February 2020 to April 2021',
    content: [{
      position: 'Junior UI Engineer',
      description: [
        'Developing core UI functionalities and components as part of the Nike team.',
        'Participation in the design and development of high-performance business applications in the field of e-commerce.',
        'Teamwork on Agile Software Development Methodology.',
        'Analysis and improvement of business application performance.',
        'Participation in the creation of technical documentation.',
      ],
    }, {
      position: 'UI Engineer Intern',
      description: [
        'Building an e-commerce website with user authentication and checkout in React JS.',
        'Conducting software analysis, programming, testing and debugging.',
        'Participating in code reviews and scrum meetings.',
      ],
    }],
    imageURL: gridDynamicsImg,
    link: 'https://www.griddynamics.com',
  },
  raf: {
    title: 'Union University, Faculty of Computing',
    date: 'October 2018 to October 2020',
    content: [{
      position: 'Teaching Assistant',
      description: [
        'Engaged in Introduction to Programming course.',
        'Teaching college students fundamentals of C programming language.',
      ],
    }],
    imageURL: rafImg,
    link: 'https://raf.edu.rs/en',
  },
  flock: {
    title: 'Flock Social',
    date: 'September 2018 to August 2019',
    content: [{
      position: 'Software Engineer',
      description: [
        'Wrote web crawlers that repeatedly extract data from web pages, parse it as JSON objects which boosted data quality and provided valuable data analytics.',
        'Created data visualizations and dashboards in Kibana using data from Twilio API in order to analyze promotional and customer service SMS delivery and performance which led to an increase in website traffic and user conversions.',
        'Conducted code peer reviews with other members of my team.',
      ],
    }],
    imageURL: flockImg,
    link: 'https://flocksocial.com',
  },
  telekom: {
    title: 'Telekom Serbia',
    date: 'August 2018 to February 2019',
    content: [{
      position: 'Data Analyst',
      description: [
        'Created machine learning jobs to automate anomaly detection.',
        'Designed and developed pipelines to analyze a large amount of telecommunications data and logs using Logstash and Filebeat.',
      ],
    }],
    imageURL: telekomImg,
    link: 'https://www.mts.rs',
  },
  startup: {
    title: 'Startup',
    date: 'July 2017 to September 2017',
    content: [{
      position: 'Full Stack Engineer',
      description: [
        'Developed responsive MEAN stack web applications.',
        'Provided one-on-one mentoring to assist less experienced colleagues in developing coding skills.',
        'Facilitate data upload 30% by creating administrator content management system.',
      ],
    }],
    imageURL: startupImg,
    link: '#',
  },
  devana: {
    title: 'Devana Technologies',
    date: 'July 2016 to September 2016',
    content: [{
      position: 'Frontend Developer Intern',
      description: [
        'Worked in a small and efficient team as the youngest team member.',
        'Created slider and roadmap for business notification application.',
      ],
    }],
    imageURL: devanaImg,
    link: 'http://labs.devana.rs',
  },
};

export const honorsAndAwards = {
  rafHackathon2019: {
    title: 'RAF Hackathon',
    place: '2nd place',
    date: 'December 2019',
    description: 'Our solution was the recommendation system for the group of users who are most likely to complete a particular survey and, by connecting them with relevant surveys, we encourage a higher percentage of successfully completed surveys.',
    imageURL: rafHackathon2019,
  },
  aibg2019: {
    title: 'AIBG',
    place: '3rd place',
    date: 'December 2019',
    description: 'Created an intelligent bot to play the given video game on its own.',
    imageURL: aibg2019,
  },
  ibmDatathon2019: {
    title: 'IBM Datathon',
    place: '3rd place',
    date: 'September 2019',
    description: 'Our solution was the Risk Assessment Prediction Tool of the supplier transactions.',
    imageURL: ibmDatathon2019,
  },
  codeAI2019: {
    title: 'Code AI',
    place: '2nd place',
    date: 'May 2019',
    description: 'The solution was the automated monitoring of crops using drones. We maximize yields and reduce costs with drone data using the CNN model to detect plant disease.',
    imageURL: codeAI2019,
  },
  fonHackathon2019: {
    title: 'FON Hackathon',
    place: '2nd place',
    date: 'March 2019',
    description: 'Our solution was a chatbot that provides users with information on the distribution of airborne allergens with the help of an interactive map. The system uses ML to predict the concentration of allergens in the air based on wind direction and air humidity.',
    imageURL: fonHackathon2019,
  },
  sicefHackathon2018: {
    title: 'SICEF Hackathon',
    place: 'finalist',
    date: 'November 2018',
    description: 'Created native mobile app which recognizes pain severity using face detection of demented people.',
    imageURL: sicefHackathon2018,
  },
  nasaSpaceAppsChallenge2018: {
    title: 'NASA Space Apps Challenge',
    place: '3rd place',
    date: 'October 2018',
    description: 'Implemented a tool which creates a unique piece of art using NASA Earth imagery data and Google Deep Dream.',
    imageURL: nasaSpaceAppsChallenge2018,
  },
  codeGovernment2018: {
    title: 'CodeGovernment',
    place: '3rd place',
    date: 'May 2018',
    description: 'Developed web app with simple UX design and smart search engine which simplifies usage of eGovernment portal.',
    imageURL: codeGovernment2018,
  },
  matHack2018: {
    title: 'MatHack',
    place: 'finalist',
    date: 'May 2018',
    description: 'Made web app that generates optimal trajectory from starting to ending point on map using machine learning algorithms.',
    imageURL: matHack2018,
  },
  fonHackathon2018: {
    title: 'FON Hackathon',
    place: '1st place',
    date: 'April 2018',
    description: 'The solution was a distributed cloud-based analysis system with interactive data visualization. The system contains a tool for personalized package recommendation, as well as a tool for detecting irregularities in the network in real-time.',
    imageURL: fonHackathon2018,
  },
};

export const certificates = {
  awsCloudPractitioner: {
    title: 'AWS Certified Cloud Practitioner',
    description: 'The AWS Certified Cloud Practitioner examination is intended for individuals who have the knowledge and skills necessary to effectively demonstrate an overall understanding of the AWS Cloud, independent of specific technical roles addressed by other AWS Certifications. The exam can be taken at a testing center or from the comfort and convenience of a home or office location as an online proctored exam.',
    date: 'December 2020',
    imageURL: awsCertifiedCloudPractitionerImg,
    link: 'https://www.youracclaim.com/badges/eb946ac4-0ba3-40c6-a102-e3b8f3da5806/public_url',
  },
};

export const partners = {
  joberty: {
    title: 'Joberty',
    buttonLink: 'https://www.joberty.rs/partner/1226/dzimiks',
    content: [{
      id: 'joberty',
      description: [
        'Joberty je platforma koja omogućava zaposlenima u IT kompanijama da podele svoja iskustva u radu kod poslodavaca, a kandidatima da pronađu posao koji im najviše odgovara.',
        'Naš cilj je da vas povežemo sa kolegama iz drugih kompanija radi iskrene i slobodnije razmene informacija.',
      ],
    }],
    imageURL: jobertyImg,
    link: 'https://www.joberty.rs',
  },
  netokracija: {
    title: 'Netokracija',
    buttonLink: 'https://www.netokracija.rs/vanja-paunovic-youtube-dzimiks-177214',
    content: [{
      id: 'netokracija',
      description: [
        'Netokracija je poslovno-tehnološki medij koji prati kulturu tehnološkog preduzetništva, digitalni marketing i nove tehnologije – na prostoru Srbije, Hrvatske i Slovenije.',
        'Kompanija je nastala 2009. godine u Hrvatskoj, 2012. proširila se na tržište Slovenije, a od 2015.',
        'Netokracija je prisutna i u Srbiji gde se bavi lokalnim pitanjima i razvojem domaće digitalne zajednice.',
      ],
    }],
    imageURL: netokracijaImg,
    link: 'https://www.netokracija.rs',
  },
  SUFTN: {
    title: 'SUFTN',
    buttonLink: 'https://www.codeference.org/assets/pages/news/takmicenje.html',
    content: [{
      id: 'SUFTN',
      description: [
        'Studentska unija FTN je organizacija usmerena na lični i profesionalni razvoj studenata i stvaranje mogućnosti za obrazovanje po svetskim standardima.',
      ],
    }],
    imageURL: suftnImg,
    link: 'https://www.facebook.com/unijaftn',
  },
};

export const mediaPartners = {
  // WATFOI: {
  //   title: 'WATFOI',
  //   description: 'We are the FUTURE of IT je prva studentska IT konferencija koja za cilj ima stvaranje preduslova za upoznavanje studenata sa znanjima i veštinama u oblasti informacionih tehnologija i upoznavanje studenata sa načinima i uslovima poslovanja IT kompanija, a sve kako bi što lakše stupili na poslovno tržište.',
  //   imageURL: watfoiImg,
  //   link: 'https://wearethefutureofit.org',
  // },
  SUFTN: {
    title: 'SUFTN',
    buttonLink: 'https://www.codeference.org/assets/pages/news/takmicenje.html',
    content: [{
      id: 'SUFTN',
      description: [
        'Studentska unija FTN je organizacija usmerena na lični i profesionalni razvoj studenata i stvaranje mogućnosti za obrazovanje po svetskim standardima.',
      ],
    }],
    imageURL: suftnImg,
    link: 'https://www.facebook.com/unijaftn',
  },
};

export const toTheMoonFAQ = [
  {
    id: 1,
    question: 'Koje programske jezike i tehnologije smem da koristim?',
    answer: 'Možete koristiti bilo koji programski jezik i bilo koji framework/tehnologiju/biblioteku/bazu podataka/open-source API. Što se mene tiče, možete kucati i u Assembly-ju, samo što će mi biti teže da pregledam rad. :)',
  },
  {
    id: 2,
    question: 'Da li pravo učešća imaju i programeri koji nisu ni studenti ni srednjoškolci?',
    answer: 'Takmičenje je namenjeno svim početnicima u programiranju i mladim ljudima. Limit je do 26 godina, a ukoliko ste nedavno diplomirali, na IT praksi ste ili ste junior programer, apsolutno imate pravo da učestvujete. U ovo spadaju i studenti koji nisu sa tehničkih fakulteta.',
  },
  {
    id: 3,
    question: 'Poslao/la sam pogrešan link u formi za prijavu. Da li mogu ponovo da pošaljem?',
    answer: 'Opušteno. Javite se slobodno na mejl dzimiksbusiness@gmail.com ili ponovo pošaljite prijavu preko forme sa ove stranice. Samo naglasite vaše ime u mejlu, a svi commit-ovi nakon 1. maja 2021, 23:59 CET se svakako neće gledati. Uzimam poslednju verziju pre ponoći sa GitHuba i nju pokrećem u svom lokalnom okruženju.',
  },
  {
    id: 4,
    question: 'Šta je tema ovog takmičenja?',
    answer: 'Ne postoji određena tema. Poenta je da napravite program ili aplikaciju koja će biti korisna studentima, odnosno učenicima - šta god to za vas značilo.',
  },
  {
    id: 5,
    question: 'Kada je krajnji rok za predaju projekta?',
    answer: 'Rok za predaju projekta je do 1. maja 2021. 23:59 CET računajući i prvi maj. Dakle, prvi maj do ponoći. Takođe, gleda se i vreme poslednjeg commit-a na vašem GitHub-u.',
  },
  {
    id: 6,
    question: 'Na osnovu kojih kriterijuma će biti ocenjivani projekti?',
    answer: 'Projekti će biti ocenjeni na osnovu nekoliko kriterijuma, uključujući kreativnost, tehničku složenost, korisnost, prezentaciju i generalno koliko je projekat zanimljiv. Usredsredite se na izgradnju nečega što vas zanima i smatrate da bi studentima bilo korisno.',
  },
  {
    id: 7,
    question: 'Da li mogu da postavim projekat na GitLab?',
    answer: 'Možete postaviti projekat na GitHub/GitLab/Bitbucket, samo nemojte na Redmine, molim vas, znam da volite profesora Perišića, ali pls, nemojte Redmine. :)',
  },
  {
    id: 8,
    question: 'Da li je potrebno da aplikacija bude korisna svim studentima ili može biti korisna i za studente specifičnog fakulteta?',
    answer: 'Kao što sam i naveo u najavi takmičenja, ne postoji određena tema, ali trebalo bi da napravite web/mobilnu/desktop/konzolnu aplikaciju koja može biti korisna studentima. To može biti vezano i za neki specifičan fakultet ili bilo koji vid olakšavanja rada/života studenata u Srbiji. Budite kreativni, svaka ideja se uvažava! Šta god za vas predstavlja korisna aplikacija za studente, to i napravite. :)',
  },
  {
    id: 9,
    question: 'Da li smem da deploy-ujem svoj projekat na neki hosting?',
    answer: 'S obzirom da deploy projekta na live domen nije tražen, neće uticati na odluku o kvalitetu projekta i vašem tehničkom znanju. To je veliki plus ukoliko znate, ali vam neće dodeliti bonus poene.',
  },
  {
    id: 10,
    question: 'Da li mogu da koristim statične/mock podatke pošto nijedan fakultet ne daje API koji mi je potreban za aplikaciju kako bi podaci bili dinamični?',
    answer: 'Ukoliko želite da vam podaci budu dinamični (da biste verodostojno prikazali neku funkcionalnost i slično), slobodno možete napraviti mock podatke koji vam odgovaraju, postavite vremenski timeout i vraćate različite podatke. Možete napraviti JSON fajl ili svoj API, kako god mislite da će vam biti korisno. Ukoliko želite da koristite bazu, možete je podići lokalno ili koristiti neki besplatan servis (npr. ako koristite MongoDB, imate MongoDB Cloud Service - MongoDB Atlas). Kompleksnija rešenja će nositi više poena u ocenjivanju. Poenta je da se koriste podaci u aplikaciji, makar bili i mock. Tako će aplikacija imati smisla i biće lepa celina. :)',
  },
  {
    id: 11,
    question: 'Da li se računaju commit-ovi pre 8. marta kada je proglašen početak takmičenja?',
    answer: 'Ne. Ceo projekat mora biti kreiran od trenutka proglašenja takmičenja. Dakle, svi commit-ovi od 8. marta do 1. maja do ponoći će doći u obzir kao validna prijava.',
  },
  {
    id: 12,
    question: 'Da li mogu da predam projekat koji sam već radio/la ranije?',
    answer: 'Ovo je razlog zašto sam postavio da je 10 commit-ova minimum za priznavanje rešenja. Ovo ne znači da u prvom commit-u možete da postavite ceo projekat i u narednih >= 9 ažurirate README fajl i slično. Molim vas da budete fer prema sebi i drugima! Naravno, ja ne mogu znati da li je projekat započet za vreme takmičenja ili ranije, ali ukoliko primetim commit-ove sa ogromnim/sumnjivim delovima koda, osoba će biti diskvalifikovana i neće moći da se bori za nagradu.',
  },
];

export const programmingContests = [
  {
    title: 'First TO THE MOON 2021',
    date: '8th March, 2021 - 1st May, 2021',
    description: 'Prvo online individualno srpsko takmičenje u programiranju - TO THE MOON! Ovo je prvi izazov u programiranju koji sam organizovao, a sponzorisale su ga kompanije Joberty i Netokracija. Ne postoji određena tema, ali trebalo bi da napravite web/mobilnu/desktop/konzolnu aplikaciju koja može biti korisna studentima. Imate pravo da budete maksimalno kreativni i inovativni. Projekti će biti ocenjeni na osnovu nekoliko kriterijuma, uključujući kreativnost, tehničku složenost, korisnost i generalno koliko je projekat zanimljiv. Usredsredite se na izgradnju nečega što vas zanima i smatrate da bi studentima bilo korisno.',
    imageURL: firstToTheMoon2021Img,
    link: '/contests/first-to-the-moon-2021',
  },
];

export const firstToTheMoonParticipants = [
  {
    name: 'Sava Nakarada',
    place: 1,
    githubLink: 'https://github.com/SavaN222/Learn-Together',
    youtubeLink: 'https://youtu.be/qTPnJl19dKA',
    certificate: savaCertImg,
  },
  {
    name: 'Petar Kovačević',
    place: 2,
    githubLink: 'https://github.com/JohnnyQuidd/Student2Students',
    youtubeLink: 'https://youtu.be/eUWOTAN1lPQ',
    certificate: petarCertImg,
  },
  {
    name: 'Mihailo Marčetić',
    place: 3,
    githubLink: 'https://github.com/marceta99/student-pomocnik',
    youtubeLink: 'https://youtu.be/Nj4R5mrVlGs',
    certificate: mihailoCertImg,
  },
  {
    name: 'Miloš Ilić',
    githubLink: 'https://github.com/misa998/studyHelper',
    youtubeLink: 'https://youtu.be/5v92CZ8MUys',
    certificate: milosIlicCertImg,
  },
  {
    name: 'Miloš Kožović',
    githubLink: 'https://github.com/DzIGIBAUER/ImageViewer',
    youtubeLink: 'https://youtu.be/EhOoicyQTbE',
    certificate: milosKozovicCertImg,
  },
  {
    name: 'Radoje Jezdić',
    githubLink: 'https://github.com/Jezda1337/foodApp',
    youtubeLink: 'https://youtu.be/iGJyK-ZR3SY',
    certificate: radojeCertImg,
  },
  {
    name: 'Dejan Bojović',
    githubLink: 'https://github.com/DejanBojovic/StudentSurvivalGuide',
    youtubeLink: 'https://youtu.be/ZX3hZpHz6D4',
    certificate: dejanCertImg,
  },
  {
    name: 'Aleksa Crveni',
    githubLink: 'https://github.com/WickedyWick/zemljopis',
    youtubeLink: 'https://youtu.be/lvL02iGEqRk',
    certificate: aleksaCertImg,
  },
  {
    name: 'Danilo Mandušić',
    githubLink: 'https://github.com/DaniloMandusic/Document-Scanner',
    youtubeLink: 'https://youtu.be/u86TmohBqdA',
    certificate: daniloCertImg,
  },
];
