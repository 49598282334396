import React from 'react';
import { Link } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Button from '@material-ui/core/Button';

import classnames from 'classnames/bind';
import styles from './blogPage.module.scss';

import istanbulCoverImage from '../../assets/images/blog/istanbul-new-year-2020/istanbul-cover.jpg';
import HeaderBanner from '../../components/Banner/HeaderBanner';

const cx = classnames.bind(styles);

function BlogPage() {
  return (
    <>
      <HeaderBanner title="Blog" />

      <div className="container" style={{ marginTop: '5rem', marginBottom: '2rem' }}>
        <div className="row">
          <div className="col-12">
            <Link className={cx('full-width')} href="/blog/istanbul-new-year-2020">
              <img
                className={cx('img-fluid', 'full-width')}
                src={istanbulCoverImage}
                alt="dzimiks"
              />
            </Link>

            <h2 className={cx('blog-post-title')}>
              <Link href="/blog/istanbul-new-year-2020">
                <Typography variant="h4">
                  Istanbul Nova Godina 2020
                </Typography>
              </Link>
            </h2>

            <ul className={cx('blog-post-meta')}>
              <li>12.01.2020.</li>
              <li>
                Author: <Link href="/about"><Typography component="p">dzimiks</Typography></Link>
              </li>
            </ul>

            <p className={cx('blog-post-content', 'mt-4')}>
              Konačno sam se uputio na dugo željenu destinaciju - <b>Istanbul</b>. Ovoga puta,
              poveo sam svoju sestru <Link href="https://www.instagram.com/olja_paunovic">Olju</Link> i to
              je bilo naše prvo zajedničko putovanje. Istanbul je fenomenalan grad! Ostavio je
              snažan utisak na nas svojom veličinom, lepotom i šarenilom. Za tri dana koliko smo
              proveli u najvećem gradu Turske, nismo uspeli da obiđemo sve što smo planirali i
              želeli da vidimo, zato što je to NEMOGUĆE - toliko stvari se može doživeti, videti,
              a, bogami, i pojesti.
            </p>

            <Button
              variant="contained"
              color="primary"
              startIcon={<VisibilityIcon />}
              href="/blog/istanbul-new-year-2020"
            >
              Continue Reading
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}

export default BlogPage;
