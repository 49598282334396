import axios from 'axios';

const CHANNEL_ID = process.env.REACT_APP_YOUTUBE_CHANNEL_ID || process.env.YOUTUBE_CHANNEL_ID;
const API_KEY = process.env.REACT_APP_YOUTUBE_API_KEY || process.env.YOUTUBE_API_KEY;
const YOUTUBE_BASE_URL = 'https://www.googleapis.com/youtube/v3/search';

/**
 * Returns latest YouTube videos.
 *
 * @param result
 * @param order
 * @returns {Promise<AxiosResponse<any>|[]|*[]>}
 */
export const getLatestYoutubeVideos = (result = 3, order = 'date') => {
  const YOUTUBE_URL = `${YOUTUBE_BASE_URL}?key=${API_KEY}&channelId=${CHANNEL_ID}&part=snippet,id&order=${order}&maxResults=${result}`;
  return axios.get(YOUTUBE_URL)
    .then(({ data }) => data.items.map((item) => item.id.videoId).filter(Boolean))
    .catch(() => []);
};

// TODO: Write function to return most viewed videos
export const getBestVideos = () => ['2hwb4vdzqmw', 'Yg0izQHjjeg', 'ZoxJg7hVt7s'];
