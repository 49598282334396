import React from 'react';
import classnames from 'classnames/bind';
import { partners } from '../../utils';
import PartnerCard from '../../components/Cards/PartnerCard';
import styles from './partnersPage.module.scss';
import HeaderBanner from '../../components/Banner/HeaderBanner';

const cx = classnames.bind(styles);

function PartnersPage() {
  const partnersKeys = Object.keys(partners);

  return (
    <>
      <HeaderBanner title="Partners" />

      <div className={cx('container', 'gutter')}>
        <div className={cx('row', 'row-eq-height')}>
          {partnersKeys.map((key) => (
            <div
              key={partners[key].title}
              className={cx('col-md-4', 'col-12')}
            >
              <PartnerCard
                title={partners[key].title}
                description={partners[key].description}
                content={partners[key].content}
                buttonLink={partners[key].buttonLink}
                imageURL={partners[key].imageURL}
                link={partners[key].link}
              />
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default PartnersPage;
