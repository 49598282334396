import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import GitHubIcon from '@material-ui/icons/GitHub';

import {
  Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
} from '@material-ui/core';
import firstPlaceImg from '../../assets/images/1st_place_transparent.png';
import secondPlaceImg from '../../assets/images/2nd_place_transparent.png';
import thirdPlaceImg from '../../assets/images/3rd_place_transparent.png';

import classes from './Card.module.scss';

function ParticipantCard({
  name,
  place,
  githubLink,
  youtubeLink,
  certificate,
}) {
  const [open, setOpen] = useState(false);
  const videoId = youtubeLink.split('/').pop();

  const capitalize = (str) => {
    let result = str[0].toUpperCase();

    // eslint-disable-next-line no-plusplus
    for (let i = 1; i < str.length; i++) {
      if (str[i - 1] === ' ') {
        result += str[i].toUpperCase();
      } else {
        result += str[i];
      }
    }
    return result;
  };

  const getProjectName = () => {
    const projectName = githubLink.split('/').pop();

    return capitalize(
      projectName
        .split(/(\d+)/g)
        .join(' ')
        .replace('-', ' ')
        .split(/([A-Z]+[^A-Z]*|[^A-Z]+)/g)
        .join(' '),
    );
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Card className={`${classes.card} text-center`}>
      <CardActionArea>
        <iframe
          title={youtubeLink}
          width="100%"
          height="300"
          src={`https://www.youtube.com/embed/${videoId}`}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </CardActionArea>

      <CardContent>
        <Typography variant="h4">
          {getProjectName()}
        </Typography>

        <h3>{name}</h3>

        <div className="mb-4">
          <Button variant="outlined" color="primary" onClick={handleClickOpen}>
            Sertifikat
          </Button>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">TO THE MOON Sertifikat</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <img src={certificate} alt="To The Moon 2021" className="img-fluid" />
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary" autoFocus>
                Ok
              </Button>
            </DialogActions>
          </Dialog>

        </div>

        <Button
          variant="contained"
          color="primary"
          href={githubLink}
          startIcon={<GitHubIcon />}
        >
          Pogledaj na GitHubu
        </Button>

        {place !== 0 && (
          <div className="mt-3">
            {place === 1 && <img width="100px" className="img-fluid" src={firstPlaceImg} alt="To The Moon 2021" />}
            {place === 2 && <img width="100px" className="img-fluid" src={secondPlaceImg} alt="To The Moon 2021" />}
            {place === 3 && <img width="100px" className="img-fluid" src={thirdPlaceImg} alt="To The Moon 2021" />}
          </div>
        )}
      </CardContent>
    </Card>
  );
}

ParticipantCard.propTypes = {
  name: PropTypes.string.isRequired,
  githubLink: PropTypes.string.isRequired,
  youtubeLink: PropTypes.string.isRequired,
  certificate: PropTypes.string.isRequired,
  place: PropTypes.number,
};

ParticipantCard.defaultProps = {
  place: 0,
};

export default ParticipantCard;
