import React from 'react';
import PropTypes from 'prop-types';

import 'react-toastify/dist/ReactToastify.css';
import { Slide, ToastContainer } from 'react-toastify';

import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';

function PageLayout(props) {
  const { children, themeMode, setThemeMode } = props;

  return (
    <div>
      <Header themeMode={themeMode} setThemeMode={setThemeMode} />
      <div className="App-content">
        {children}
      </div>
      <ToastContainer
        newestOnTop
        transition={Slide}
      />
      <Footer />
    </div>
  );
}

PageLayout.propTypes = {
  children: PropTypes.node.isRequired,
  themeMode: PropTypes.bool.isRequired,
  setThemeMode: PropTypes.func.isRequired,
};

export default PageLayout;
