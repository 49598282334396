import React from 'react';
import PropTypes from 'prop-types';

import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { Link } from '@material-ui/core';
import classes from './Card.module.scss';

function MediaCard({
  title,
  place,
  date,
  description,
  content,
  imageURL,
  link,
}) {
  const isDescriptionArray = Array.isArray(description);

  const Description = isDescriptionArray ? () => (
    <>
      {description.map((desc) => (
        <Typography key={desc} variant="body2" color="textSecondary" component="p">
          - {desc}
        </Typography>
      ))}
    </>
  ) : () => (
    <Typography variant="body2" color="textSecondary" component="p">
      {description}
    </Typography>
  );

  return (
    <Card className={classes.card}>
      <CardActionArea>
        {link !== ''
          ? (
            <Link href={link}>
              <CardMedia
                className={classes.media}
                image={imageURL}
                title={title}
              />
            </Link>
          )
          : (
            <CardMedia
              className={classes.media}
              image={imageURL}
              title={title}
            />
          )}
      </CardActionArea>

      <CardContent>
        <Typography variant="h4">
          {place ? `${title} (${place})` : title}
        </Typography>

        <Typography gutterBottom variant="body2" color="textSecondary" component="p">
          {date}
        </Typography>

        {content && content.map((item) => (
          <div key={item.position}>
            <Typography gutterBottom variant="h6">
              {item.position}
            </Typography>

            {item.description.map((desc) => (
              <Typography key={desc} variant="body2" color="textSecondary" component="p">
                - {desc}
              </Typography>
            ))}
          </div>
        ))}
        <Description />
      </CardContent>
    </Card>
  );
}

MediaCard.propTypes = {
  title: PropTypes.string.isRequired,
  place: PropTypes.string,
  date: PropTypes.string.isRequired,
  description: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string,
  ]),
  content: PropTypes.arrayOf(PropTypes.object),
  imageURL: PropTypes.string.isRequired,
  link: PropTypes.string,
};

MediaCard.defaultProps = {
  place: '',
  content: [],
  link: '',
  description: '',
};

export default MediaCard;
