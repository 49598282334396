import React, { useState } from 'react';
import axios from 'axios';
import classnames from 'classnames/bind';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import SendIcon from '@material-ui/icons/Send';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import styles from './contactPage.module.scss';

import showNotification from '../../services/notifications';
import { messageTypes, notificationTypes } from '../../consts';
import HeaderBanner from '../../components/Banner/HeaderBanner';

const cx = classnames.bind(styles);

function ContactPage() {
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [message, setMessage] = useState('');

  const resetForm = () => {
    setFullName('');
    setEmail('');
    setPhoneNumber('');
    setMessage('');
  };

  const handleSendEmail = async (event) => {
    event.preventDefault();

    const data = {
      phoneNumber,
      message,
      email,
      fullName,
    };

    await axios.post('/send-email', { data })
      .then((response) => {
        const { mailSent, message: responseMessage } = response.data;

        if (mailSent) {
          resetForm();
          showNotification({
            type: notificationTypes.NOTIFICATION_SUCCESS,
            message: responseMessage,
          });
        } else {
          showNotification({
            type: notificationTypes.NOTIFICATION_ERROR,
            message: responseMessage,
          });
        }
      })
      .catch(() => {
        showNotification({
          type: notificationTypes.NOTIFICATION_ERROR,
          message: messageTypes.MESSAGE_BAD_REQUEST,
        });
      });
  };

  return (
    <>
      <HeaderBanner title="Contact Me" />

      <div className={cx('container', 'gutter')}>
        <div className="row">
          <Card className={cx('card', 'col-md-6', 'mb-4', 'mx-auto')}>
            <CardContent>
              <form onSubmit={handleSendEmail}>
                <div className="row">
                  <div className="col-12">
                    <TextField
                      className={cx('form__input')}
                      placeholder="Full name"
                      value={fullName}
                      onChange={(event) => setFullName(event.target.value)}
                      error={fullName === ''}
                      label="Full name*"
                      helperText={fullName === '' ? 'Insert your full name!' : ' '}
                      fullWidth
                    />
                  </div>

                  <div className="col-12">
                    <TextField
                      className={cx('form__input')}
                      placeholder="Email"
                      value={email}
                      onChange={(event) => setEmail(event.target.value)}
                      error={email === ''}
                      label="Email*"
                      type="email"
                      helperText={email === '' ? 'Insert valid email!' : ' '}
                      fullWidth
                    />
                  </div>

                  <div className="col-12">
                    <TextField
                      className={cx('form__input')}
                      placeholder="Phone number"
                      value={phoneNumber}
                      onChange={(event) => setPhoneNumber(event.target.value)}
                      label="Phone number"
                      fullWidth
                    />
                  </div>

                  <div className="col-12">
                    <TextField
                      className={cx('form__input', 'form__message')}
                      placeholder="Message"
                      value={message}
                      onChange={(event) => setMessage(event.target.value)}
                      helperText={message === '' ? 'Insert your message!' : ' '}
                      error={message === ''}
                      label="Message*"
                      variant="outlined"
                      rowsMax={10}
                      multiline
                      fullWidth
                    />
                  </div>

                  <div className="col-12 text-center">
                    <Button
                      type="submit"
                      variant="text"
                      color="primary"
                      startIcon={<SendIcon />}
                      disabled={fullName === '' || email === '' || message === ''}
                    >
                      Send Email
                    </Button>
                  </div>
                </div>
              </form>
            </CardContent>
          </Card>
        </div>
      </div>
    </>
  );
}

export default ContactPage;
