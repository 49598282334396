import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames/bind';
import styles from './HeaderBanner.module.scss';

import pageTitleImage from '../../assets/images/illustrations/page-title.png';
import dotsGroupSmImage from '../../assets/images/illustrations/dots-group-sm.svg';
import dotsGroupCyanImage from '../../assets/images/illustrations/dots-group-cyan.svg';
import dotsCyanImage from '../../assets/images/illustrations/dots-cyan.svg';
import leafYellowImage from '../../assets/images/illustrations/leaf-yellow.svg';
import leafOrangeImage from '../../assets/images/illustrations/leaf-orange.svg';
import leafCyanImage from '../../assets/images/illustrations/leaf-cyan.svg';
import leafCyanLgImage from '../../assets/images/illustrations/leaf-cyan-lg.svg';
import leafPinkRoundImage from '../../assets/images/illustrations/leaf-pink-round.svg';

const cx = classnames.bind(styles);

function HeaderBanner({ title }) {
  const [background, setBackground] = useState('primary');

  useEffect(() => {
    const backgrounds = ['primary', 'secondary', 'tertiary', 'quaternary'];
    const random = Math.floor(Math.random() * backgrounds.length);
    setBackground(backgrounds[random]);
  }, []);

  const PrimaryBackground = () => (
    <section className={cx(
      'page-title-alt',
      `bg--${background}`,
      'position-relative',
      'overflow-hidden',
    )}
    >
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h1 className={cx('text-white', 'about__title')}>{title}</h1>
          </div>
        </div>
      </div>

      <img src={pageTitleImage} alt="dzimiks" className={cx('bg-shape-1', 'w-100')} />
      <img src={dotsGroupSmImage} alt="dzimiks" className={cx('bg-shape-2')} />
      <img src={leafYellowImage} alt="dzimiks" className={cx('bg-shape-3')} />
      <img src={leafOrangeImage} alt="dzimiks" className={cx('bg-shape-4')} />
      <img src={dotsGroupCyanImage} alt="dzimiks" className={cx('bg-shape-5')} />
      <img src={leafCyanImage} alt="dzimiks" className={cx('bg-shape-6')} />
    </section>
  );

  const SecondaryBackground = () => (
    <section className={cx(
      'page-title',
      `bg--${background}`,
      'position-relative',
      'overflow-hidden',
    )}
    >
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h1 className={cx('text-white', 'about__title')}>{title}</h1>
          </div>
        </div>
      </div>

      <img src={pageTitleImage} alt="dzimiks" className={cx('bg-shape-1', 'w-100')} />
      <img src={leafPinkRoundImage} alt="dzimiks" className={cx('bg-shape-2')} />
      <img src={dotsCyanImage} alt="dzimiks" className={cx('bg-shape-3')} />
      <img src={leafOrangeImage} alt="dzimiks" className={cx('bg-shape-4')} />
      <img src={leafYellowImage} alt="dzimiks" className={cx('bg-shape-5')} />
      <img src={dotsGroupCyanImage} alt="dzimiks" className={cx('bg-shape-6')} />
      <img src={leafCyanLgImage} alt="dzimiks" className={cx('bg-shape-7')} />
    </section>
  );

  return (
    <>
      {background === 'primary' ? <PrimaryBackground /> : <SecondaryBackground />}
    </>
  );
}

HeaderBanner.propTypes = {
  title: PropTypes.string.isRequired,
};

export default HeaderBanner;
