import React, { useEffect, useState } from 'react';
import classnames from 'classnames/bind';

import Typography from '@material-ui/core/Typography';
import { Link } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import LaptopMacIcon from '@material-ui/icons/LaptopMac';
import YouTubeIcon from '@material-ui/icons/YouTube';
import CodeIcon from '@material-ui/icons/Code';

import { isEmpty } from 'lodash';
import personalText from '../../../assets/images/personal_text.svg';
import devProductivity from '../../../assets/images/dev_productivity.svg';

import styles from './LandingPage.module.scss';
import MediaCard from '../../../components/Cards/MediaCard';
import Banner from '../Banner/Banner';
import Skillbar from '../../../components/Skillbar/Skillbar';

import { certificates, honorsAndAwards, workExperience } from '../../../utils';
import { getBestVideos } from '../../../services/youtube';

const cx = classnames.bind(styles);

function LandingPage() {
  const workExperienceKeys = Object.keys(workExperience);
  const honorsAndAwardsKeys = Object.keys(honorsAndAwards);
  const certificatesKeys = Object.keys(certificates);

  const [latestYoutubeVideos, setLatestYoutubeVideos] = useState([]);

  useEffect(() => {
    // TODO: Mix it up!
    // getLatestYoutubeVideos()
    //   .then((data) => setLatestYoutubeVideos(data));
    setLatestYoutubeVideos(getBestVideos());
  }, []);

  return (
    <>
      <Banner height="100vh" primary>
        <div className={cx('container', 'text-left')}>
          <div className={cx('row')}>
            <div className={cx('col-12')}>
              <div className={cx('title__box')}>
                <Typography variant="h3" gutterBottom>
                  Hi, I'm Vanja Paunović.
                </Typography>

                <div className={cx('d-flex')}>
                  <LaptopMacIcon className={cx('icon-mr')} />
                  <Typography gutterBottom>
                    Frontend Engineer at <Link target="_blank" href="https://tenderly.co">Tenderly</Link>
                  </Typography>
                </div>

                <div className={cx('d-flex')}>
                  <YouTubeIcon className={cx('icon-mr')} />
                  <Typography gutterBottom>
                    Check out my <Link target="_blank" href="https://www.youtube.com/c/dzimiks">YouTube channel</Link>
                  </Typography>
                </div>

                <div className={cx('d-flex')}>
                  <CodeIcon className={cx('icon-mr')} />
                  <Typography gutterBottom>
                    Winners of the <Link href="/contests">Serbian Software Engineering Competition 2021</Link>
                  </Typography>
                </div>

                <div className={cx('buttons')}>
                  <Button
                    variant="contained"
                    color="primary"
                    href="/contact"
                    className={cx('button')}
                  >
                    Hire me
                  </Button>

                  <Button
                    variant="contained"
                    color="primary"
                    target="_blank"
                    href="/files/VanjaPaunovicCV.pdf"
                  >
                    View my resume
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Banner>

      <div className="container">
        <div className={cx('root', 'align-center', 'row')}>
          <div className={cx('col-md-6', 'col-12')}>
            <h1 className={cx('main-title')}>Summary</h1>
            <p>
              Hi, my name is Vanja!
            </p>

            <p>
              I'm a software engineer with 4+ years of diverse, professional experience. I'm always striving to make
              things faster, simpler, and more user and developer-friendly.
            </p>

            <p>
              UI development is the most enjoyable part of my career. I always strive to write well-designed,
              performant, fully tested code that works across multiple browsers. For the past couple of years, I have
              been focused on React and Redux development but I am well-versed in many other libraries/frameworks.
            </p>

            <p>
              More recently, I'm interested in blockchain development and the whole of its ideology. Right now, I'm
              working on a tool for real-time monitoring, alerting, and troubleshooting for Smart Contracts.
            </p>

            <p>
              Despite enjoying the front end, I tend to float toward whatever project needs the most attention, taking
              ownership and ensuring success, regardless of comfort level. I regularly develop on OSX and deploy
              across a variety of platforms. This has required close collaboration with a multitude of stakeholders,
              which includes security engineers, technical architects, product owners, managers, and designers from
              all around the world.
            </p>

            <p>
              The end result is a wide variety of experiences, from REST-based microservice development to writing
              test automation frameworks, large database migrations, authentication and authorization, and more.
            </p>

            <p>
              I do not mind jumping in and getting my hands dirty in existing projects. I thrive under pressure and
              love fast-paced companies that deliver compelling products.
            </p>
          </div>

          <div className={cx('col-md-6', 'col-12', 'text-center')}>
            <img src={personalText} alt="dzimiks" className={cx('image')} />
          </div>
        </div>
      </div>

      <Banner>
        <Typography variant="h3">YouTube Videos</Typography>
      </Banner>

      <div className={cx('container')}>
        <div className={cx('row')}>
          <div className={cx('col-12')}>
            <p>
              Recently, I've started a YouTube channel - <a href="https://www.youtube.com/c/dzimiks">dzimiks</a>. Here I
              will talk about the life of
              programmers in Serbia, big tech companies, programming, and my adventures that
              I go through as a programmer. If you liked the video, please leave a comment or
              share it with your friends that are interested in such a topic.
            </p>
          </div>

          {!isEmpty(latestYoutubeVideos) ? (
            latestYoutubeVideos.map((videoId) => (
              <div
                className={cx('col-lg-4', 'py-3')}
                key={videoId}
              >
                <iframe
                  title={videoId}
                  width="100%"
                  height="250"
                  src={`https://www.youtube.com/embed/${videoId}`}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </div>
            ))
          ) : (
            <>
              <div className={cx('col-lg-4', 'py-3')}>
                <iframe
                  title="YouTube #5"
                  width="100%"
                  height="250"
                  src="https://www.youtube.com/embed/2hwb4vdzqmw"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </div>

              <div className={cx('col-lg-4', 'py-3')}>
                <iframe
                  title="YouTube #4"
                  width="100%"
                  height="250"
                  src="https://www.youtube.com/embed/Yg0izQHjjeg"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </div>

              <div className={cx('col-lg-4', 'py-3')}>
                <iframe
                  title="YouTube #3"
                  width="100%"
                  height="250"
                  src="https://www.youtube.com/embed/ZoxJg7hVt7s"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </div>
            </>
          )}
        </div>
      </div>

      <Banner>
        <Typography variant="h3">Work Experience</Typography>
      </Banner>

      <div className="container">
        <div className={cx('root', 'row', 'row-eq-height')}>
          {workExperienceKeys.map((key) => (
            <div
              key={workExperience[key].title}
              className={cx('col-lg-4', 'col-sm-6')}
            >
              <MediaCard
                title={workExperience[key].title}
                date={workExperience[key].date}
                description={workExperience[key].description}
                content={workExperience[key].content}
                imageURL={workExperience[key].imageURL}
                link={workExperience[key].link}
              />
            </div>
          ))}
        </div>
      </div>

      <Banner>
        <Typography variant="h3">Skills</Typography>
      </Banner>

      <div className="container">
        <div className={cx('root', 'align-center', 'row')}>
          <div className={cx('col-md-6', 'col-12', 'text-center')}>
            <img
              src={devProductivity}
              alt="dzimiks"
              style={{ maxWidth: 250 }}
            />
          </div>

          <div className={cx('col-md-6', 'col-12')}>
            <Skillbar size="85" skills="Frontend Development (React JS, Vue JS, HTML5, CSS3, JavaScript, jQuery)" />
            <Skillbar size="75" skills="Backend Development (NodeJS, ExpressJS, Golang)" />
            <Skillbar size="70" skills="Data Structures (Java, C/C++)" />
            <Skillbar size="55" skills="Data Science (Python, ELK Stack, Machine Learning)" />
            <Skillbar size="80" skills="Databases (MongoDB, Redis, MySQL, Postgres)" />
          </div>
        </div>
      </div>

      <Banner>
        <Typography variant="h3">Honors & Awards</Typography>
      </Banner>

      <div className={cx('container')}>
        <div className={cx('root', 'row', 'row-eq-height')}>
          {honorsAndAwardsKeys.map((key) => (
            <div
              key={`${honorsAndAwards[key].title} (${honorsAndAwards[key].place})`}
              className={cx('col-md-6', 'col-12')}
            >
              <MediaCard
                title={honorsAndAwards[key].title}
                place={honorsAndAwards[key].place}
                date={honorsAndAwards[key].date}
                description={honorsAndAwards[key].description}
                imageURL={honorsAndAwards[key].imageURL}
              />
            </div>
          ))}
        </div>
      </div>

      <Banner>
        <Typography variant="h3">Certificates</Typography>
      </Banner>

      <div className={cx('container')}>
        <div className={cx('root', 'row', 'row-eq-height')}>
          {certificatesKeys.map((key) => (
            <div
              key={`${certificates[key].title}`}
              className={cx('col-md-6', 'col-12')}
            >
              <MediaCard
                title={certificates[key].title}
                description={certificates[key].description}
                date={certificates[key].date}
                imageURL={certificates[key].imageURL}
                link={certificates[key].link}
              />
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default LandingPage;
