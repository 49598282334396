import React from 'react';
import Link from '@material-ui/core/Link';
import classnames from 'classnames/bind';
import styles from '../blogPage.module.scss';

import istanbulkartImg from '../../../assets/images/blog/istanbul-new-year-2020/istanbulkart.png';
import biletmatikImg from '../../../assets/images/blog/istanbul-new-year-2020/biletmatik.jpg';
import blueMosqueDzimiksImg from '../../../assets/images/blog/istanbul-new-year-2020/blue-mosque-dzimiks.jpg';
import blueMosqueImg from '../../../assets/images/blog/istanbul-new-year-2020/blue-mosque.jpg';
import hagiaSophiaDzimiksAndOljaImg
  from '../../../assets/images/blog/istanbul-new-year-2020/hagia-sophia-dzimiks-and-olja.jpg';
import hagiaSophiaDzimiksImg from '../../../assets/images/blog/istanbul-new-year-2020/hagia-sophia-dzimiks.jpg';
import basilicaCisternImg from '../../../assets/images/blog/istanbul-new-year-2020/basilica-cistern-1.jpg';
import basilicaCisternMedusaImg from '../../../assets/images/blog/istanbul-new-year-2020/basilica-cistern-medusa.jpg';
import topkapiPalaceImg from '../../../assets/images/blog/istanbul-new-year-2020/topkapi-palace.jpg';
import topkapiPalace2Img from '../../../assets/images/blog/istanbul-new-year-2020/topkapi-palace-2.jpg';
import guhlaneParkDzimiksImg from '../../../assets/images/blog/istanbul-new-year-2020/guhlane-park-dzimiks.jpg';
import guhlaneParkImg from '../../../assets/images/blog/istanbul-new-year-2020/guhlane-park.jpeg';
import grandBazaar1Img from '../../../assets/images/blog/istanbul-new-year-2020/grand-bazaar-1.jpg';
import grandBazaar2Img from '../../../assets/images/blog/istanbul-new-year-2020/grand-bazaar-2.jpg';
import spiceBazaar1Img from '../../../assets/images/blog/istanbul-new-year-2020/spice-bazaar-1.jpg';
import spiceBazaar2Img from '../../../assets/images/blog/istanbul-new-year-2020/spice-bazaar-2.jpg';
import balat1Img from '../../../assets/images/blog/istanbul-new-year-2020/balat-1.jpg';
import balat2Img from '../../../assets/images/blog/istanbul-new-year-2020/balat-2.jpg';
import galataBridge1Img from '../../../assets/images/blog/istanbul-new-year-2020/galata-bridge-1.jpg';
import galataBridge2Img from '../../../assets/images/blog/istanbul-new-year-2020/galata-bridge-2.jpg';
import galataTower1Img from '../../../assets/images/blog/istanbul-new-year-2020/galata-tower.jpg';
import galataTower2Img from '../../../assets/images/blog/istanbul-new-year-2020/galata-tower-2.jpg';
import rainbowStairs1Img from '../../../assets/images/blog/istanbul-new-year-2020/rainbow-stairs.jpg';
import rainbowStairs2Img from '../../../assets/images/blog/istanbul-new-year-2020/rainbow-stairs-2.jpg';
import taksimSquareImg from '../../../assets/images/blog/istanbul-new-year-2020/taksim-square.jpeg';
import istiklalImg from '../../../assets/images/blog/istanbul-new-year-2020/istiklal.jpeg';
import dolmabahcePalace1Img from '../../../assets/images/blog/istanbul-new-year-2020/dolmabahce-palace.jpg';
import dolmabahcePalace2Img from '../../../assets/images/blog/istanbul-new-year-2020/dolmabahce-palace-2.jpg';
import kizKulesi1Img from '../../../assets/images/blog/istanbul-new-year-2020/kiz-kulesi-1.jpg';
import kizKulesi2Img from '../../../assets/images/blog/istanbul-new-year-2020/kiz-kulesi-2.jpg';
import seaLife1Img from '../../../assets/images/blog/istanbul-new-year-2020/sea-life-1.jpeg';
import seaLife2Img from '../../../assets/images/blog/istanbul-new-year-2020/sea-life-2.jpeg';
import hafizImg from '../../../assets/images/blog/istanbul-new-year-2020/hafiz.jpeg';
import hafiz2Img from '../../../assets/images/blog/istanbul-new-year-2020/hafiz-2.jpeg';
import balkanImg from '../../../assets/images/blog/istanbul-new-year-2020/balkan.jpeg';
import kumpirImg from '../../../assets/images/blog/istanbul-new-year-2020/kumpir.jpeg';
import nusretSalataImg from '../../../assets/images/blog/istanbul-new-year-2020/nusret-salata.jpeg';
import nusretBurgerImg from '../../../assets/images/blog/istanbul-new-year-2020/nusret-burger.jpeg';

import sladoledStoTeZezaVideo from '../../../assets/images/blog/istanbul-new-year-2020/sladoled-sto-te-zeza.mp4';
import nusretBaklavaVideo from '../../../assets/images/blog/istanbul-new-year-2020/nusret-baklava.mp4';

const cx = classnames.bind(styles);

function IstanbulNYE2020() {
  return (
    <>
      <div className={cx('blog-post-banner-image', 'mb-3', 'centered')}>
        <div className={cx('blog-post-title-container')}>
          <h1 className={cx('blog-post-banner-title')}>Istanbul Nova Godina 2020</h1>

          <ul className={cx('blog-post-meta')}>
            <li>12.01.2020</li>
            <li>
              Author: <Link href="/about" color="inherit">dzimiks</Link>
            </li>
          </ul>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-12">
            <h2>Najveći grad u Evropi</h2>

            <p>
              Konačno sam se uputio na dugo željenu destinaciju - <b>Istanbul</b>. Ovoga puta,
              poveo sam svoju sestru <Link href="https://www.instagram.com/olja_paunovic">Olju</Link> i
              to je bilo naše prvo zajedničko putovanje. Istanbul je fenomenalan grad! Ostavio je
              snažan utisak na nas svojom veličinom, lepotom i šarenilom. Za tri dana koliko smo
              proveli u najvećem gradu Turske, nismo uspeli da obiđemo sve što smo planirali i
              želeli da vidimo, zato što je to NEMOGUĆE - toliko stvari se može doživeti, videti,
              a, bogami, i pojesti.
            </p>

            <p>Istanbul je jedinstven po mnogo čemu:</p>

            <ul>
              <li>jedini grad koji se nalazi na dva kontinenta - <b>Evropa</b> i <b>Azija</b>,</li>
              <li>
                nekadašnja je velika prestonica 3 velika carstva
                – <b>Rimskog</b>, <b>Vizantijskog</b> i <b>Osmanskog</b>,
              </li>
              <li>u njemu živi preko <b>15 miliona</b> stanovnika, što ga čini najvećim gradom
                Turske, ali nije i glavni grad ove države, već je to <b>Ankara</b>.
              </li>
            </ul>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <h2>Valuta i kurs</h2>

            <p>
              Zvanične valute Republike Turske je <b>turska lira (TRY)</b> sa oznakom <b>₺</b>.
              Kao i niz drugih valute Evrope i Srednjeg istoka, turska lira vodi poreklo od
              starorimske jedinice za težinu - libre. U svojoj istoriji, ova valuta doživela je
              niz inkarnacija. Otomanska lira je bila u upotrebi od 1844. do 1923. godine,
              nasledila ju je prva turska lira (od 1923. do 2005), dok je druga turska lira u
              promet puštena 2005. godine, i u upotrebi je i danas. 1 turska lira sastoji se
              od <b>100 kuruša</b>.
            </p>

            <p>Što se tiče kursne liste, na današnji dan izgleda ovako:</p>

            <div className="bold">
              <p>1 TL = 17.67 RSD</p>
              <p>1 EURO = 6.66 TL</p>
              <p>1 USD = 5.95 TL</p>
            </div>

            <p className="small">* TL - turska lira</p>

            <p>
              <b>Napomena:</b> kurs zna da bude različit u menjačnicama, tako da je moj savet da
              gledate gde je kurs za evro iznad <b>6.60 TL</b> - najbolje je da svoj novac
              zamenite u okolini <b>Grand Bazaara</b>.
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <h2>Prevoz</h2>

            <p>
              S obzirom da smo išli na put preko
              agencije <Link
                target="_blank"
                className="bold"
                href="https://www.jungletribe.rs"
                rel="noopener noreferrer"
              >Jungle Tribe
              </Link>, imali smo obezbeđen autobuski prevoz. Put, zajedno sa
              smeštajem, je iznosio <b>120 EUR</b> po osobi.
            </p>

            <p>
              Kome je ipak naporno (a naporno je) da putuje mnogo sati autobusom,
              preporučujem <b>Turkish Airlines</b>. Avio karte se mogu naći i
              za <b>110 EUR</b> po osobi, uključujući sve takse. Morate voditi računa
              koliko ranije kupujete karte po povoljnoj tarifi, jer se brzo rasprodaju.
              Niska cena sa sobom nosi i restriktivne uslove, te kartu morate odmah i platiti,
              a u slučaju otkazivanja neće vam biti vraćen novac.
            </p>

            <p>
              Kada stignete u Istanbul, moja preporuka je da
              kupite <Link
                target="_blank"
                className="bold"
                href="https://youtu.be/wpjFUM3Xvkc"
                rel="noopener noreferrer"
              >
                Istanbulkart
              </Link> - odlična je za turiste i ima ulogu kao
              naš <b>BusPlus</b>, samo je bolje. Ovu karticu
              možete iskoristiti za tramvaje, autobuse, trajekte... Sve što je potrebno je da odete
              do <b>žute mašine</b> koja se nalazi blizu svake stanice i podignete svoju karticu.
              Cena
              je <b>6 TL</b>, dok za dopunu, koja se naziva <b>Topup</b>, možete uplatiti u papirnim
              novčanicama koliko god želite da potrošite. Ono što je super je da možete otići do
              najbliže prodavnice i da zamenite svoju Istanbulkart za <b>5 TL</b>. Takođe, uvek je
              možete poneti kući kao suvenir.
            </p>

            <div className="row">
              <div className="col-md-6 col-12">
                <img
                  src={istanbulkartImg}
                  alt="Istanbulkart"
                  className="mb-3 img-fluid full-width"
                />
              </div>

              <div className="col-md-6 col-12">
                <img
                  src={biletmatikImg}
                  alt="Istanbulkart machine"
                  className="mb-3 img-fluid full-width"
                />
              </div>
            </div>

            <p>
              Generalno, prevoz je jako jeftin u Istanbulu i kreće se od <b>2.60 TL (46 RSD)</b>.
              Iako postoje velike
              gužve (pogotovo za praznike), saobraćaj funkcioniše gotovo savršeno - tramvaji dolaze
              na dva minuta
              razmaka, metro je superbrz i jako je lako stići gde god poželite. Ono što je
              zanimljivo
              je to da Turci
              baš i ne prate saobraćajne signale, tako da smo zajedno sa grupama ljudi kršili mnogo
              zakona prelazeći
              ulice na crveno svetlo.
            </p>

            <p>
              Želim javno da se zahvalim Google mapama - najjači izum u 21. veku. Uz
              pomoć <b>offline mape</b> Istanbula i GPS-a smo svuda išli i stizali na vreme.
              Zapamtite ovo ako nemate
              interneta!
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <h2>Smeštaj</h2>

            <p>
              Kao što sam rekao, smeštaj nam je bio obezbeđen od strane agencije i postojale su
              2 opcije: <Link
                href="http://www.piyahostel.com/tr"
              >Piya hostel
              </Link> i <Link href="http://ilkbaldeluxehotel.com/en/homepage">Ilkbal Deluxe 3*</Link>.
              Generalno, hosteli nisu baš
              prijatni kao hoteli, ali ako želite da posetite neku destinaciju a ne potrošite puno
              novca na smeštaj,
              onda vam hosteli dođu kao super opcija. Bitno je da ima tople vode i gde da se legne.
            </p>

            <p>
              <b>Piya hostel</b> se nalazi u centru starog grada, 600 m od Trga Sultanahmet na
              kome se nalaze Aja
              Sofija i Plava džamija. Kupatila su zajednička, a sobe su 1/3, 1/4, 1/6 i 1/8.
              Usluga je na bazi noćenja
              sa doručkom.
            </p>

            <Link
              href="http://www.piyahostel.com/tr"
              rel="noopener noreferrer"
              target="_blank"
            ><i className="fa fa-link" /> Sajt
            </Link>

            <p>
              <b className="d-block"><i className="fa fa-map-marker" /> Adresa</b><br />
              Sultanahmet Mahallesi Akbıyık Cami Sokak<br />
              Akbıyık Cd. No:22<br />
              34122<br />
              Turkey
            </p>
            <p>
              <b>Ilkbal Deluxe 3*</b> se nalazi u centru starog grada, 200 m od
              glavne ulice Ordu Cadesi, 900 m od
              Kapali čaršije i 1.5 km od Trga Sultanahmet. Sobe su 1/2 i svaka ima kupatilo.
              Usluga je na bazi noćenja
              sa doručkom.
            </p>

            <Link
              href="http://ilkbaldeluxehotel.com/en/homepage"
              rel="noopener noreferrer"
              target="_blank"
            >
              <i className="fa fa-link" /> Sajt
            </Link>

            <p>
              <b className="d-block"><i className="fa fa-map-marker" /> Adresa</b><br />
              Mimar Kemalettin<br />
              Koska Cd. No:43<br />
              34470 Fatih/İstanbul
            </p>

            <p>
              Naravno, tu je uvek i <Link href="https://www.booking.com">Booking.com</Link> gde
              možete online rezervisati
              sobe od <b>50 EUR</b> i više za noć. Sem komentara gostiju objavljenih na Booking-u,
              pročitajte i
              komentare na sajtu <Link href="https://www.tripadvisor.com">Tripadvisor</Link>.
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <h2>Wi-Fi</h2>

            <p>
              Pored korišćenja besplatnog WiFi-ja u kafićima i restoranima, postoji nekoliko
              različitih načina kako
              možete koristiti internet u Istanbulu:
            </p>

            <h4>ibbWiFi</h4>

            <p>
              Istanbul nudi besplatnu Wi-Fi konekciju koju sponzoriše lokalna opština preko
              nekoliko hotspot lokacija
              širom grada - <b>ibbWiFi</b>. Za upotrebu ove usluge potrebno je prvo da se
              registrujete.
            </p>

            <p>
              ibbWiFi ima hotspotove na svim trgovima glavnih turističkih četvrti i
              četvrti kao što su Sultanahmet,
              Taksim, Eminonu, Bešiktaš, kao i u nekim parkovima i međugradskim autobusnim
              terminalima.
            </p>

            <p>
              Ono što nije dobro je što može retko raditi, ponekad se možete teško registrovati,
              konektovati i to na
              vrlo maloj brzini. Dobićete samo <b>1 GB</b> besplatnog interneta dnevno
              sa brzinom od <b>2 Mbps</b>.
            </p>

            <h4>Pocket WiFi (Mobile Hotspot)</h4>

            <p>
              Iznajmljivanje <b>Pocket WiFi-ja</b>, najefikasniji je, najsigurniji i najpraktičniji
              način za dobijanje
              WiFi-ja u Istanbulu ili celoj Turskoj. Ovaj prenosivi mali uređaj i brzi mobilni
              hotspot nude pouzdan i
              neograničen internet u Istanbulu, a svoj laptop i telefon možete da povežete
              sa internetom gde god da se
              nalazite u Istanbulu koristeći 4G vezu.
            </p>

            <p>
              Takođe možete da delite internet vezu sa do <b>10 ljudi</b> bez dodatnih troškova.
              Odlična opcija za
              grupu prijatelja. Jednostavno, morate ostati unutar <b>15 metara</b> od osobe
              koja nosi uređaj.
            </p>

            <p>
              Mnogo je kompanija koje pružaju Pocket WiFi rešenja i dobra stvar je što ih
              možete koristiti u mnogim
              zemljama poput Turske, EU ili Velike Britanije. Dostavljaju vam se u hotel ili ga
              možete preuzeti sa
              aerodroma u New Istanbul Airport (IST) ili Sabiha Gökçen International Airport (SAW).
            </p>

            <h4>SIM kartica</h4>

            <p>
              Za one koji će da pozivaju i primaju pozive, tada kupovina turske SIM kartice
              ili unapred plaćene
              kartice sa kombinovanim WiFi-om može biti dobro rešenje.
            </p>

            <p>
              U Turskoj postoje samo tri GSM provajdera koji vam mogu podesiti SIM karticu. Tu
              spadaju <b>Vodafone</b>, <b>Turkcell</b> i <b>Turk Telekom</b>. Prema turskom zakonu,
              sve SIM kartice
              moraju biti registrovane i mogu se kupiti samo sa važećim pasošem. Svi nude planove
              sa različitim
              količinama podataka.
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <h2>Znamenitosti</h2>

            <p>
              Iii najzanimljiviji deo putovanja - šta se sve to može videti u jednom od najvećih
              gradova na
              svetu!? <b>Istanbul je P R E L E P!</b> Da smo imali još par dana na raspolaganju,
              ne bismo stigli da
              posetimo
              ni delić onoga što nam ovaj grad nudi. Toliko zanimljivih lokacija, spomenika,
              zgrada, ulica, mačaka (o
              da, ima ih na sve strane, Olja je svojim rendgenskim vidom uspela da ih prebroji
              mnogo puta u toku
              šetnje), hrane, posetilaca...
            </p>

            <p>
              Naš smeštaj se nalazio blizu mora u samom centru starog dela grada. U radijusu
              od 2 km se nalaze
              najpoznatije znamenitosti u Istanbulu. Većinu vremena smo proveli pešačeći, a
              za malo dalje destinacije
              smo koristili tramvaj <b>T1</b> koji se pokazao kao super opcija.
            </p>

            <h4>Plava Džamija (The Blue Mosque)</h4>

            <p>
              <Link href="https://goo.gl/maps/RUJ5MEzSopVfavms9"><b>Plava Džamija</b></Link> jedna
              je od glavnih turističkih
              atrakcija u Istanbulu. Evropljani su joj dali ime Plava džamija zbog plavih pločica
              koje prekrivaju
              unutrašnjost kupole. Sagrađena je početkom 17. veka, i poput ostalih džamija sadrži
              grobnicu osnivača.
              Nekada je od nje kretalo hodočašće ka Meki i upravo zbog njenih 6 minareta na
              najvećoj svetinji
              muslimana - džamiji u Meki je dodat i sedmi.
            </p>

            <p>
              Ono što se meni mnogo svidelo
              je <Link href="https://goo.gl/maps/kQmsdSv2YHy9n8X39"><b>Trg Sultanahmet</b></Link>.
              Park je prelep i jako lepo sređen, a u centru se nalazi ukrašena fontana. Stvarno
              vredi prošetati ovim delom grada. Ulaz u džamiju je besplatan, ali nije
              dozvoljen za vreme molitve.
            </p>

            <div className="row">
              <div className="col-sm-6 col-12 my-auto">
                <img
                  className="img-fluid mb-3"
                  src={blueMosqueDzimiksImg}
                  alt="The Blue Mosque"
                />
              </div>

              <div className="col-sm-6 col-12 my-auto">
                <img
                  className="img-fluid mb-3"
                  src={blueMosqueImg}
                  alt="The Blue Mosque"
                />
              </div>
            </div>

            <h4>Aja Sofija (Hagia Sophia)</h4>

            <p>
              <Link href="https://g.page/ayasofyamuseum?share"><b>Aja Sofija</b></Link>, ili
              kako je mnogi zovu, crkva svete
              mudrosti, pravo je remek-delo vizantijske arhitekture. Bila je najveća crkva u
              nekadašnjem Rimskom
              carstvu i najpoznatija crkva u hrišćanstvu oko 9 vekova. Stubovi su joj od
              najvrednijeg mermera iz
              Mramornog mora, Nikeje i Južne Afrike i u jednom od tih stubova postoji šupljina
              u koju stavite palac,
              okrenete ga za 360 stepeni i zamislite želju. Kažu, garantovano se ispunjava!
            </p>

            <p>
              Aja Sofija je jedna masivna građevina koja izgleda neverovatno iz svakog ugla.
              Letnje radno vreme je
              od <b>9h</b> do <b>19h</b>, a zimsko od <b>9h</b> do <b>17h</b>. Cena karte
              je <b>40 TL</b>.
            </p>

            <div className="row">
              <div className="col-sm-6 col-12 my-auto">
                <img
                  className="img-fluid mb-3"
                  src={hagiaSophiaDzimiksAndOljaImg}
                  alt="Hagia Sophia"
                />
              </div>

              <div className="col-sm-6 col-12 my-auto">
                <img
                  className="img-fluid mb-3"
                  src={hagiaSophiaDzimiksImg}
                  alt="Hagia Sophia"
                />
              </div>
            </div>

            <h4>Cisterna bazilika (Basilica Cistern)</h4>

            <p>
              <Link href="https://goo.gl/maps/oJdMKvzJDLbTScdM8"><b>Cisterna bazilika</b></Link> ili <b>Potopljena
                palata
              </b> - zamislite 336 stubova poređanih u 12 redova. Nekada je omogućavala
              pristup svežoj pijaćoj
              vodi koja se dopremala iz dalekih akvadukta iz Beogradske šume. Najviše ima stubova
              izrađenih u jonskom
              i korintskom stilu. U severozapadnom delu cisterne nalaze se dva stuba koji u osnovi
              imaju glave meduze.
              Radno vreme je svakog dana od <b>9h</b> do <b>17:30h</b>, a cena karte
              je <b>20 TL</b>.
            </p>

            <div className="row">
              <div className="col-sm-6 col-12 my-auto">
                <img
                  className="img-fluid mb-3"
                  src={basilicaCisternImg}
                  alt="Basilica Cistern"
                />
              </div>

              <div className="col-sm-6 col-12 my-auto">
                <img
                  className="img-fluid mb-3"
                  src={basilicaCisternMedusaImg}
                  alt="Basilica Cistern"
                />
              </div>
            </div>

            <h4>Topkapi palata (Topkapi Palace)</h4>

            <p>
              <Link href="https://g.page/topkapi-palace-museum?share"><b>Topkapi</b></Link> je
              palata sultana koja je po
              mnogo čemu razlikuje od evropskih palata i dvoraca. To je kompleks koji se sastoji
              od više odaja -
              sultanova rezidencija, kuhinja, harem, riznica... U Topkapiju se čuva Mojsijev štap,
              drugi najveći
              dijamant na svetu, svete relikvije sultana Selima i još mnogo toga.
            </p>

            <p>
              Otvorena svaki dan, osim utorka, od <b>9h</b> do <b>18:45h</b>, u letnjem periodu, i
              od <b>9h</b> do <b>16:45h</b>
              u zimskom. Cena karte je <b>30 TL + Harem 15 TL</b>.
            </p>

            <div className="row">
              <div className="col-sm-6 col-12 my-auto">
                <img
                  className="img-fluid mb-3"
                  src={topkapiPalaceImg}
                  alt="Topkapi"
                />
              </div>

              <div className="col-sm-6 col-12 my-auto">
                <img
                  className="img-fluid mb-3"
                  src={topkapiPalace2Img}
                  alt="Topkapi"
                />
              </div>
            </div>

            <h4>Gülhane Park</h4>

            <p>
              U neposrednoj blizini Topkapi palate nalazi se jedan od najlepših parkova
              koje sam video - <Link
                href="https://goo.gl/maps/SXcBmGLyyCZ9Vkah6"
              ><b>Gülhane Park</b>
              </Link>. Prelepi, spokojni šumski
              park koji sadrži vrtove, staze, potok i naučni muzej. Klupice su postavljene
              na samo par metara jedna od
              druge, kao i dosta prelepo ukrašenih kanti za đubre (bukvalno su toliko fensi da
              te bude žao da ih
              koristiš).
            </p>

            <div className="row">
              <div className="col-sm-6 col-12 my-auto">
                <img
                  className="img-fluid mb-3"
                  src={guhlaneParkDzimiksImg}
                  alt="Gülhane Park dzimiks"
                />
              </div>

              <div className="col-sm-6 col-12 my-auto">
                <img
                  className="img-fluid mb-3"
                  src={guhlaneParkImg}
                  alt="Gülhane Park"
                />
              </div>
            </div>

            <h4>Grand Bazaar (Kapalı Çarşı)</h4>

            <p>
              <Link href="https://goo.gl/maps/meKYYStM9C7asmhM6"><b>Grand bazar</b></Link> je
              najveća i najstarija
              natkrivena tržnica na svetu. Sastoji se od više od <b>4000</b> dućana koje su
              razvrstane u 60 ulica.
              Morate imati jake živce za ovakvo iskustvo, jer će vas na, bukvalno, svakom
              koraku zaustavljati i nuditi
              vam svoju robu. Ne zaboravite da bazar ne radi nedeljom. U nekim delovima
              je dosta neuredno i nije lako provući se kroz gužvu. Ono što je obavezno
              je <b>cenkanje</b>. Konačno
              sam mogao da primenim znanja iz Pawn Stars-a.
            </p>

            <div className="row">
              <div className="col-sm-6 col-12 my-auto">
                <img
                  className="img-fluid mb-3"
                  src={grandBazaar1Img}
                  alt="Grand Bazaar"
                />
              </div>

              <div className="col-sm-6 col-12 my-auto">
                <img
                  className="img-fluid mb-3"
                  src={grandBazaar2Img}
                  alt="Grand Bazaar"
                />
              </div>
            </div>

            <h4>Egipatski Bazaar (Mısır Çarşısı)</h4>

            <p>
              <Link href="https://goo.gl/maps/hD2KTFwge3N54sDbA"><b>Egipatski bazar</b></Link> ili <b>Bazar
                začina
              </b> je
              mesto koje ne smete zaobići. Fenomenalno je organizovano tako da možete videti
              numerisane radnje sa
              milion različitih začina. Vrlo je uredno i prostrano, mnogo lepše od Grand Bazaara.
            </p>

            <div className="row">
              <div className="col-sm-6 col-12 my-auto">
                <img
                  className="img-fluid mb-3"
                  src={spiceBazaar1Img}
                  alt="Mısır Çarşısı"
                />
              </div>

              <div className="col-sm-6 col-12 my-auto">
                <img
                  className="img-fluid mb-3"
                  src={spiceBazaar2Img}
                  alt="Mısır Çarşısı"
                />
              </div>
            </div>

            <h4>Oblast Balat (Balat District)</h4>

            <p>
              <Link href="https://goo.gl/maps/j9PvghJ9ezFDAoTV8"><b>Balat</b></Link> je kvart
              uskih kaldrmisanih ulica i
              šarenih kuća, gde kafići i vrhunske galerije sede pored prodavnica starog
              školskog naselja.
            </p>

            <div className="row">
              <div className="col-sm-6 col-12 mx-auto">
                <img
                  className="img-fluid mb-3"
                  src={balat1Img}
                  alt="Balat"
                />
              </div>

              <div className="col-sm-6 col-12 mx-auto">
                <img
                  className="img-fluid mb-3"
                  src={balat2Img}
                  alt="Balat"
                />
              </div>
            </div>

            <h4>Most Galata (Galata Bridge)</h4>

            <p>
              <Link href="https://goo.gl/maps/KSLSbT3LDWD5Dkey7"><b>Galata most</b></Link> spaja
              dve velike oblasti u
              Istanbulu - <b>Eminönü</b> i <b>Karaköy</b>. Zanimljivo je da se ispod samog mosta
              nalaze restorani i
              kafići sa nargilama koji rade po ceo dan i noć. Takođe, možete zateći i dosta ljudi
              koji pecaju.
            </p>

            <div className="row">
              <div className="col-sm-6 col-12 my-auto">
                <img
                  className="img-fluid mb-3"
                  src={galataBridge1Img}
                  alt="Galata Bridge"
                />
              </div>

              <div className="col-sm-6 col-12 my-auto">
                <img
                  className="img-fluid mb-3"
                  src={galataBridge2Img}
                  alt="Galata Bridge"
                />
              </div>
            </div>

            <h4>Toranj Galata (Galata Tower)</h4>

            <p>
              <Link href="https://goo.gl/maps/pdJfPpMX7X3829zQ7"><b>Galata toranj</b></Link> je
              srednjovekovna kamena kula u
              četvrti Galata / Karaköy u Istanbulu, severno od raskrsnice Zlatnog roga i
              Bosfora. To je visoki
              cilindar sa konusnim poklopcem koji pruža panoramski pogled na istorijski deo
              Istanbula i njegovu
              okolinu. Nismo imali priliku da se popnemo na vrh jer je bila ogromna gužva i jako
              je teško doći na red od turista.
            </p>

            <div className="row">
              <div className="col-sm-6 col-12 my-auto">
                <img
                  className="img-fluid mb-3"
                  src={galataTower1Img}
                  alt="Galata Tower"
                />
              </div>

              <div className="col-sm-6 col-12 my-auto">
                <img
                  className="img-fluid mb-3"
                  src={galataTower2Img}
                  alt="Galata Tower"
                />
              </div>
            </div>

            <h4>Rainbow Stairs</h4>

            <p>
              <Link href="https://goo.gl/maps/HixmZ1Yh56tPGD2U8"><b>Rainbow Stairs</b></Link> je
              gradsko stepenište u duginim bojama i deo je gradskog gerilskog street art projekta.
              Prava su atrakcija sa one strane mosta.
            </p>

            <div className="row">
              <div className="col-sm-6 col-12 my-auto">
                <img
                  className="img-fluid mb-3"
                  src={rainbowStairs1Img}
                  alt="Rainbow Stairs"
                />
              </div>

              <div className="col-sm-6 col-12 my-auto">
                <img
                  className="img-fluid mb-3"
                  src={rainbowStairs2Img}
                  alt="Rainbow Stairs"
                />
              </div>
            </div>

            <h4>Trg Taksim (Taksim Square)</h4>

            <p>
              <Link href="https://goo.gl/maps/Yn9bmM6DW4SnBdYk6"><b>Trg Taksim</b></Link> je područje užurbanog noćnog
              života, kupovine i hrane. Starinski tramvajski šatl duž ulice <Link
                href="https://goo.gl/maps/eV1kvYtnNTSGTvM28"
              >Istiklal
              </Link>,
              glavnog gradskog pešačkog bulevara, koji je obložen zgradama iz 19. veka u
              kojima su smešteni
              međunarodni trgovački lanci, bioskopi i kafići. Gusta mreža sporednih ulica
              ispunjena je barovima,
              starinskim prodavnicama i fenomenalnim pogledom na Bosfor. Ovo je kraj za mlade i
              uvek su velike gužve.
            </p>

            <div className="row">
              <div className="col-sm-6 col-12 my-auto">
                <img
                  className="img-fluid mb-3"
                  src={taksimSquareImg}
                  alt="Taksim Square"
                />
              </div>

              <div className="col-sm-6 col-12 my-auto">
                <img
                  className="img-fluid mb-3"
                  src={istiklalImg}
                  alt="Istiklal"
                />
              </div>
            </div>

            <h4>Dolmabahče palata (Dolmabahçe Palace)</h4>

            <p>
              <Link href="https://goo.gl/maps/k59LbW1RvGkwJd1Z9"><b>Dolmabahče palata</b></Link> je
              velika, kupolasta
              sultanova palata (sada muzej) u kojoj se nalaze kolekcije umetnosti, kaligrafije i
              tepiha. Nalazi se u delu grada koji se zove Beşiktaş.
            </p>

            <div className="row">
              <div className="col-sm-6 col-12 my-auto">
                <img
                  className="img-fluid mb-3"
                  src={dolmabahcePalace1Img}
                  alt="Dolmabahçe Palace"
                />
              </div>

              <div className="col-sm-6 col-12 my-auto">
                <img
                  className="img-fluid mb-3"
                  src={dolmabahcePalace2Img}
                  alt="Dolmabahçe Palace"
                />
              </div>
            </div>

            <h4>Maiden's Tower (Kız Kulesi)</h4>

            <p>
              <Link href="https://goo.gl/maps/oNMsZgsT9au32UgUA"><b>Maiden's Tower</b></Link>, poznata i kao Leanderova
              kula
              još od srednjovekovnog vizantijskog perioda, je kula koja leži na malom ostrvu,
              smeštenom na južnom
              ulazu u Bosfor, 200 m od obale Üsküdar u Istanbulu. Nalazi se u azijskom delu
              Istanbula i do nje je moguće doći jedino brodom, odnosno trajektom.
            </p>

            <div className="row">
              <div className="col-sm-6 col-12 my-auto">
                <img
                  className="img-fluid mb-3"
                  src={kizKulesi1Img}
                  alt="Kız Kulesi"
                />
              </div>

              <div className="col-sm-6 col-12 my-auto">
                <img
                  className="img-fluid mb-3"
                  src={kizKulesi2Img}
                  alt="Kız Kulesi"
                />
              </div>
            </div>

            <h4>Sea Life</h4>

            <p>
              <Link href="https://g.page/sealifeistanbul?share"><b>Sea Life</b></Link> je veliki akvarijum na zatvorenom
              u
              tržnom centru Forum. Cena za dve osobe je <b>158 TL</b> što je oko <b>25 evra</b>.
            </p>

            <div className="row">
              <div className="col-sm-6 col-12 my-auto">
                <img
                  className="img-fluid mb-3"
                  src={seaLife1Img}
                  alt="Sea Life"
                />
              </div>

              <div className="col-sm-6 col-12 my-auto">
                <img
                  className="img-fluid mb-3"
                  src={seaLife2Img}
                  alt="Sea Life"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <h2>Hrana</h2>

            <p>
              Turska ima zanimljivu kulturu i sve se to, naravno, prenelo i na hranu.
              Mogu sa sigurnošću reći da sam
              jeo za sve pare i da je hrana fantastična. Bukvalno ne možete da odolite apsolutno
              ničemu na ulici. Od
              onog sladoleda što te zeza do kebaba kratak je put. Mi smo se trudili da probamo
              što više lokalne hrane
              i da ručamo na mestima koja nisu turistička, samim tim smo dosta uštedeli novca, s
              obzirom da se se cene
              razlikuju i do par evra za istu stvar u samo nekoliko stotina metara. Treba se pre
              puta informisati gde
              je najbolje jesti lokalnu hranu za najmanje novca.
            </p>

            <p>
              Ono što je nama privuklo pažnju su i ulični prodavci sladoleda koji te lepo
              zezaju pre nego što dobiješ
              par kugli. Cena sladoleda je <b>25 TL</b>.
            </p>

            <div className="row">
              <div className="col-sm-6 col-12 mx-auto">
                <video className="full-width" controls>
                  <track kind="captions" />
                  <source src={sladoledStoTeZezaVideo} type="video/mp4" />
                  Your browser doesn't support HTML5 video.
                </video>
              </div>
            </div>

            <div>
              <p>Probali smo razne kul stvari, a neke od njih su:</p>

              <ul>
                <li>
                  <b>Künefe</b> - tradicionalni dezert sa Bliskog Istoka, napravljen od
                  tankog testa nalik na
                  rezance, natopljenog u slatki sirup na bazi šećera, i obično slojevitog
                  sira ili sa drugim
                  sastojcima, kao što su ugrušeni krem ili orasi. Künefe i 2
                  čaja: <b>30 TL</b>.
                </li>
                <li>
                  <b>Ayran</b> - odlično piće uz kebab. Sastoji se od jogurta, vode i soli.
                </li>
                <li>
                  <b>Köfte</b> - jagnjeće ćufte pomešane sa začinima i / ili
                  lukom. <b>Sultanahmet Halkin
                    Koftecisi Selim Usta
                  </b> - 1 ayran i 1 köfte: <b>19 TL</b>.
                </li>
                <li>
                  <b>Manti</b> - vrsta knedli pravljene od jagnjetine ili mlevene govedine
                  u testu i mogu se
                  kuvati ili spremati na pari. Košta oko <b>18 TL</b>.
                </li>
                <li>
                  <b>Dagnje</b> - školjke punjene su pirinčem, poslužene sa limunom. Blizu Grand
                  Bazaara košta <b>10
                    TL
                  </b>.
                </li>
                <li>
                  <b>Kokoreç</b> - janjeća ili kozja creva sa puno začina i povrća. Blizu Grand
                  Bazaara košta <b>9
                    TL
                  </b>.
                </li>
                <li>
                  <b>Kadayif</b> - sićušni i hrskavi rezanci od brašna i vode. Pojačava se
                  maslacem, orasom ili
                  pistaćima, peče se u rerni do zlatnožute boje, a zatim se natopi sirupom, tako
                  da postane meka
                  i slatka poslastica. <b>Hafız Mustafa 1864</b> - puding od jagode, kadayif i 2
                  čaja: <b>45 TL</b>.
                </li>
                <li>
                  <b>Kumpir</b> - turska verzija pečenog krompira punjena povrćem. Meni sa koka
                  kolom košta <b>29 TL</b> u Istiklal ulici.
                </li>
                <li>
                  <b>Simit</b> - turski đevrek. Najčešće ga jedu ujutru za doručak uz čaj.
                </li>
                <li>
                  <b>Balık ekmek</b> - sendvič u kome je riba sa paradajzom, salatom.
                  Košta oko <b>15 TL</b>.
                </li>
              </ul>
            </div>

            <div className="row">
              <div className="col-sm-6 col-12 my-auto">
                <img
                  className="img-fluid mb-3"
                  src={hafizImg}
                  alt="Food"
                />
              </div>

              <div className="col-sm-6 col-12 my-auto">
                <img
                  className="img-fluid mb-3"
                  src={hafiz2Img}
                  alt="Food"
                />
              </div>

              <div className="col-sm-6 col-12 my-auto">
                <img
                  className="img-fluid mb-3"
                  src={balkanImg}
                  alt="Food"
                />
              </div>

              <div className="col-sm-6 col-12 my-auto">
                <img
                  className="img-fluid mb-3"
                  src={kumpirImg}
                  alt="Food"
                />
              </div>
            </div>

            <div>
              <p>
                Lokalni restorani i mesta gde se može naći hrana jeftinija nego na
                turističkim mestima:
              </p>

              <ul>
                <li>
                  <Link href="https://goo.gl/maps/d5uSrha1PC4YuZ6o7">Balkan Lokantası</Link> - vrhunska
                  lokalna hrana po prihvatljivoj ceni.
                </li>
                <li>
                  <Link href="https://goo.gl/maps/BLhTghwbFp6ftjPT9">Melekler Dürümevi</Link>
                </li>
                <li>
                  <Link href="https://goo.gl/maps/nSwZ2DmmSQ639Utt8">Sultanahmet Halkin Koftecisi Selim Usta</Link>
                </li>
                <li>
                  <Link href="https://goo.gl/maps/zsGWyufKfJswAgi97">Hocapaşa Pidecisi</Link>
                </li>
                <li>
                  <Link href="https://goo.gl/maps/Au7i5AuekdKDT4t89">Sayla Manti</Link>
                </li>
                <li>
                  <Link href="https://goo.gl/maps/SA13Lu6JkH3F6iug7">Karadeniz Döner</Link>
                </li>
                <li>
                  <Link href="https://goo.gl/maps/eFDUGYPD6bRQRv7aA">Gala Kokoreç Beşiktaş Çarşıiçi</Link>
                </li>
                <li>
                  <Link href="https://goo.gl/maps/EEChN2Mgb8mgntwN6">Ayla Tantuni</Link>
                </li>
                <li>
                  <Link href="https://goo.gl/maps/oVfMJFuq9QGBBqZm7">Balıkçı Tufan</Link>
                </li>
                <li>
                  <Link href="https://goo.gl/maps/fQn5zPch9SF71pVF9">Hafız Mustafa 1864</Link> - sjajna poslastičarnica
                  u centru grada.
                </li>
                <li>
                  <Link href="https://goo.gl/maps/VwNcvNdSipkFvrqW6">Ali Muhiddin Hacı Bekir Lokumları</Link>
                </li>
                <li>
                  <Link href="https://goo.gl/maps/axpK6jE7nNAchraP6">Karakoy Gulluoglu</Link> - najbolje baklave u
                  gradu.
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <h2>Kafići i restorani</h2>

            <p>
              Nismo previše vremena provodili u kafićima jer smo želeli što više da obiđemo tokom
              dana. Jedan od
              boljih koji smo posetili je bio <Link href="https://g.page/galatakonak?share">Galata
                Konak Cafe & Restaurant
              </Link> sa savršenim pogledom na Istanbul. Dve turske kafe sa ratlukom su
              koštale <b>25 TL</b>.
            </p>

            <p>Jedno od naših najlepših iskustava je bilo u restoranu <Link
              href="https://goo.gl/maps/7isKxGzi3UyF7Zb27"
            >Nusr-Et
              Steakhouse Sandal Bedesteni
            </Link> blizu Grand Bazaara. Svi smo čuli za <Link
              href="https://www.instagram.com/nusr_et"
            >#saltbae
            </Link> i Olja i ja smo odlučili da posetimo njegov
              restoran u samom centru starog grada. Ono nije restoran, ono je palata! Plafon
              je minimum 20 m visok,
              ceo restoran je na dva sprata i bilo je dosta imućnih ljudi unutra, s obzirom da su
              i cene više nego
              inače. Pored nas je neko naručio zlatni biftek i to je nešto što se ne viđa kod nas
              u Srbiji. Meso je
              mirisalo kao iz raja i konobari su se potrudili da prezentacija jela bude na visokom
              nivou. Nas dvoje
              smo naručili po burger i jednu sjajnu salatu sa kozjim sirom, brusnicama, jabukom i
              još povrća. Sigurno
              najbolja salata koju sam u životu probao.
            </p>

            <div className="row">
              <div className="col-sm-6 col-12 my-auto">
                <img
                  className="img-fluid mb-3"
                  src={nusretSalataImg}
                  alt="Nusret"
                />
              </div>

              <div className="col-sm-6 col-12 my-auto">
                <img
                  className="img-fluid mb-3"
                  src={nusretBurgerImg}
                  alt="Nusret"
                />
              </div>
            </div>

            <p>
              Za kraj smo se zasladili baklavom i to je sigurno bila najbolja prezentacija
              hrane koju sam u životu
              doživeo. Olja i ja smo ostali bez reči, a ta baklava je bila toliko dobra da smo
              želeli još po jednu,
              ali nismo mogli! Račun za nas dvoje je bio <b>220 TL</b>.
            </p>

            <div className="row">
              <div className="col-sm-6 col-12 mx-auto">
                <video className="full-width" controls>
                  <track kind="captions" />
                  <source src={nusretBaklavaVideo} type="video/mp4" />
                  Your browser doesn't support HTML5 video.
                </video>
              </div>
            </div>

            <div>
              <p>Neki od kafića za koje smo čuli da su kul:</p>

              <ul>
                <li>
                  <Link href="https://goo.gl/maps/W3G2iWSqES4G3DgXA">Limon Pide Kebap Evi</Link>
                </li>

                <li>
                  <Link href="https://goo.gl/maps/1Sxa1KccgaK5BHg68">Kybele Hotel & Cafe</Link>
                </li>

                <li>
                  <Link href="https://goo.gl/maps/kZb8MzgmUqtkVDFU9">Kurukahveci Mehmet Efendi</Link> - ovde je odlična
                  kafa.
                </li>

                <li>
                  <Link href="https://goo.gl/maps/S4GLvyqRRGHSk7BL6">Incir Agaci Kahvesi</Link>
                </li>

                <li>
                  <Link href="https://goo.gl/maps/ohFdZHSc9HkKk8YF7">Sirin Firin Cafe & Bakery</Link>
                </li>

                <li>
                  <Link href="https://goo.gl/maps/dCyksPM8c7YtZd429">F’Roses Cafe</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default IstanbulNYE2020;
