import React from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
// import Typography from '@material-ui/core/Typography';
import classes from './Spinner.module.scss';

function Spinner() {
  return (
    <div className={classes.spinner}>
      <CircularProgress />
      {/* TODO */}
      {/* <Typography variant="h5" gutterBottom> */}
      {/*  Fetching data... */}
      {/* </Typography> */}
    </div>
  );
}

export default Spinner;
