import React, { useState } from 'react';

import classnames from 'classnames';

import TextField from '@material-ui/core/TextField';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Banner from '../Landing/Banner/Banner';

import dzimiks from '../../assets/images/dzimiks-circle.png';
import classes from './LoginPage.module.scss';

function LoginPage() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleLogin = () => {

  };

  return (
    <div className={classes.root}>
      <div className={classes.item}>
        <div className={classnames('container', classes.gutter)}>
          <div className="row">
            <form className={classnames('col-12', classes.grid)}>
              <Card className={classes.card}>
                <CardContent className={classes['card-content']}>
                  <img alt="dzimiks" src={dzimiks} width="120px" />

                  <TextField
                    className={classes.textfield}
                    placeholder="Enter your username"
                    value={username}
                    onChange={(event) => setUsername(event.target.value)}
                    error={username === ''}
                    label="Username"
                    helperText={username === '' ? 'Enter your username!' : ' '}
                  />

                  <TextField
                    className={classes.textfield}
                    placeholder="Enter your password"
                    value={password}
                    onChange={(event) => setPassword(event.target.value)}
                    error={password === ''}
                    label="Password"
                    helperText={password === '' ? 'Enter your password!' : ' '}
                  />

                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<ExitToAppIcon />}
                    onClick={handleLogin}
                  >
                    Login
                  </Button>
                </CardContent>
              </Card>
            </form>
          </div>
        </div>
      </div>

      <div className={classes.item}>
        <Banner height="100vh">
          <h1>Login</h1>
        </Banner>
      </div>
    </div>
  );
}

export default LoginPage;
