import React from 'react';

import classnames from 'classnames';
import { Link } from '@material-ui/core';

import AssignmentIcon from '@material-ui/icons/Assignment';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import YouTubeIcon from '@material-ui/icons/YouTube';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import GitHubIcon from '@material-ui/icons/GitHub';
import EmailIcon from '@material-ui/icons/Email';
import Typography from '@material-ui/core/Typography';
import classes from './Footer.module.scss';

function Footer() {
  return (
    <footer className={classnames('container-fluid', classes.footer)}>
      <div className="row">
        <div className="col-12">

          <div className="container">
            <div className="row no-gutters">
              <div className="col-md-3 col-sm-6 col-12">
                <div className="footer-col-item">
                  <Typography variant="h6">Location</Typography>
                  <p>Europe, Belgrade</p>
                  <p>Republic of Serbia</p>
                </div>
              </div>

              <div className="col-md-3 col-sm-6 col-12 mb-3">
                <Typography variant="h6">About</Typography>
                <p>Hi, my name is Vanja!</p>
                <p>I am a Software Engineer from Serbia.</p>
              </div>

              <div className="col-md-3 col-sm-6 col-12 mb-3">
                <Typography variant="h6">Contact Me</Typography>
                <p className={classes['footer-contact']}>
                  <Link href="mailto:dzimiksbusiness@gmail.com">
                    <EmailIcon />
                    <span>dzimiksbusiness@gmail.com</span>
                  </Link>
                </p>
              </div>

              <div className="col-md-3 col-sm-6 col-12 mb-3">
                <Typography variant="h6">Socials</Typography>
                <p className={classes['footer-social-networks']}>
                  <Link href="/files/VanjaPaunovicCV.pdf" target="_blank">
                    <AssignmentIcon />
                  </Link>
                  <Link href="https://www.facebook.com/vanja.paunovic">
                    <FacebookIcon />
                  </Link>
                  <Link href="https://www.instagram.com/dzimiks">
                    <InstagramIcon />
                  </Link>
                  <Link href="https://www.linkedin.com/in/vpaunovic">
                    <LinkedInIcon />
                  </Link>
                  <Link href="https://www.youtube.com/c/dzimiks">
                    <YouTubeIcon />
                  </Link>
                  <Link href="https://github.com/dzimiks">
                    <GitHubIcon />
                  </Link>
                </p>
              </div>

              <div className={classnames('col-12', classes['footer-copyrights'])}>
                <p>
                  © {new Date().getFullYear().toString()} Vanja Paunović <Link href="https://www.instagram.com/dzimiks">@dzimiks</Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
