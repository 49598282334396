import React, { useState } from 'react';
import classnames from 'classnames/bind';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import InfoIcon from '@material-ui/icons/Info';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import CardMedia from '@material-ui/core/CardMedia';
import CardActionArea from '@material-ui/core/CardActionArea';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';

import styles from '../contestsPage.module.scss';

import HeaderBanner from '../../../components/Banner/HeaderBanner';
import PartnerCard from '../../../components/Cards/PartnerCard';
import ParticipantCard from '../../../components/Cards/ParticipantCard';

import {
  mediaPartners,
  partners,
  toTheMoonFAQ,
  firstToTheMoonParticipants,
} from '../../../utils';

import firstPlaceImg from '../../../assets/images/1st_place.png';
import secondPlaceImg from '../../../assets/images/2nd_place.png';
import thirdPlaceImg from '../../../assets/images/3rd_place.png';

const cx = classnames.bind(styles);

function FirstToTheMoon2021() {
  const [selectedTab, setSelectedTab] = useState(0);
  const top3Participants = firstToTheMoonParticipants.filter(({ place }) => place > 0);
  const toTheMoonParticipants = firstToTheMoonParticipants.filter((user) => !user.place);

  const handleSelectedTab = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <>
      <HeaderBanner title="🚀 TO THE MOON - prvo online individualno srpsko takmičenje u programiranju" />

      <Tabs
        value={selectedTab}
        onChange={handleSelectedTab}
        variant="fullWidth"
        indicatorColor="primary"
        textColor="primary"
        aria-label="icon label tabs"
      >
        <Tab icon={<PeopleAltIcon />} label="Takmičari" />
        <Tab icon={<InfoIcon />} label="Informacije" />
      </Tabs>

      {selectedTab === 0 && (
        <div className={cx('container', 'gutter')}>
          <div className="row mb-4">
            <div className="col-12 mb-3">
              <h1>Prvo online individualno srpsko takmičenje u programiranju TO THE MOON je uspešno završeno!</h1>
              {/* eslint-disable-next-line jsx-a11y/accessible-emoji */}
              <p>
                Videćete veoma kreativna rešenja, fenomenalne projekte, kompleksne arhitekture, kod u različitim
                tehnologijama i još mnogo toga! Ovoga puta, tri projekta su se pokazala kao dostojna mesta u TOP 3.
                Hvala vam na vašoj kreativnosti, želji da pokažete svoje znanje, mnogo linija otkucanog koda i rešenjima
                koja definitivno jesu korisna studentima. 🎉
              </p>
            </div>
            <div className="col-lg-6 col-12 mx-auto">
              <iframe
                title="OB895lyGdE0"
                width="100%"
                height="350"
                src="https://www.youtube.com/embed/OB895lyGdE0"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>
          </div>

          <div className="row mb-4">
            {/* eslint-disable-next-line jsx-a11y/accessible-emoji */}
            <h1 className="text-center">Pobednici takmičenja TO THE MOON 2021 🚀</h1>
            {top3Participants.map((user) => (
              <div className="col-lg-4 col-md-6 col-12" key={user.githubLink}>
                <ParticipantCard
                  name={user.name}
                  place={user.place}
                  githubLink={user.githubLink}
                  youtubeLink={user.youtubeLink}
                  certificate={user.certificate}
                />
              </div>
            ))}
          </div>

          <div className="row">
            <div className="col-12 text-center mb-3">
              <h1>Projekti vredni pomena</h1>
              <p className="m-0">Projekti koji će pokrenuti vašu kreativnost!</p>
              <p className="mt-0">Projekti koji će vas motivisati!</p>
            </div>

            {toTheMoonParticipants.map((user) => (
              <div className="col-lg-4 col-md-6 col-12" key={user.githubLink}>
                <ParticipantCard
                  name={user.name}
                  place={user.place}
                  githubLink={user.githubLink}
                  youtubeLink={user.youtubeLink}
                  certificate={user.certificate}
                />
              </div>
            ))}
          </div>
        </div>
      )}

      {selectedTab === 1 && (
        <div className={cx('container', 'gutter')}>
          <div className="row">
            <div className="col-12">
              <h1>
                Najava takmičenja
              </h1>

              <p>
                <b>8. marta 2021.</b> objavljujem <b>prvo online individualno srpsko takmičenje u programiranju - TO THE
                  MOON</b>!
                Ovo je prvi izazov u programiranju koji sam organizovao, a sponzorisale su ga kompanije <a
                  href="https://www.joberty.rs"
                >Joberty</a> i <a href="https://www.netokracija.rs">Netokracija</a>. Ne
                postoji određena tema, ali trebalo bi da napravite <b>web/mobilnu/desktop/konzolnu aplikaciju koja može
                  biti korisna studentima</b>. Imate pravo da budete maksimalno kreativni i inovativni. Projekti će biti
                ocenjeni na osnovu nekoliko kriterijuma, uključujući kreativnost, tehničku složenost, korisnost i
                generalno koliko je projekat zanimljiv. Usredsredite se na izgradnju nečega što vas zanima i smatrate da
                bi studentima bilo korisno.
              </p>

              <div className="row">
                <div className={cx('col-lg-6', 'py-3')}>
                  <iframe
                    title="NFJUjb1QqDM"
                    width="100%"
                    height="350"
                    src="https://www.youtube.com/embed/NFJUjb1QqDM"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  />
                </div>
              </div>

              <h1>
                Pravila takmičenja
              </h1>

              <p>
                Ispod se nalaze pravila ovog takmičenja. Prijavljeni učesnici koji ne ispunjavaju navedene uslove neće
                imati šansu da osvoje nagradu:
              </p>

              <ul>
                <li>Morate biti stanovnik <b>Republike Srbije</b></li>
                <li>
                  Morate imati <b>{'<='} 26 godina</b> (takmičenje je primarno namenjeno studentima, kao i
                  srednjoškolcima)
                </li>
                <li>Ovo je <b>individualni</b> izazov, tako da ne možete raditi u grupi/timu</li>
                <li>Možete koristiti <b>bilo koji</b> programski jezik</li>
                <li>
                  Morate da koristite <b>open-source API ili bazu podataka</b> po vašem izboru
                  (koristite <b>mock</b> podatke ako ne možete da pronađete relevantne)
                </li>
                <li>Rok za predaju projekta je do <b>1. maja 2021. 23:59 CET</b></li>
                <li>
                  Postavite projekat na svoj <b>GitHub</b> profil i u <b>README</b> fajlu objasnite način pokretanja
                </li>
                <li>
                  Imajte najmanje <b>10 commit-ova</b>, tako da to možemo smatrati aktivnim razvojem projekta (<b>NE
                    VARAJTE</b> ovde)
                </li>
                <li>Postavite <b>video na YouTube-u</b> (najviše <b>90 sekundi</b>) gde predstavljate vaš projekat</li>
              </ul>

              <h1>
                Pobednici i nagrade
              </h1>

              <p>
                Biće izabrano <b>3 pobednika</b>. Kreativni, zanimljivi i projekti vredni pomena biće prikazani na
                ovoj web stranici. Pobednici će osvojiti sledeće <b>3 nagrade</b>:
              </p>

              <div className="row">
                <div className="col-md-4 order-2 order-md-1">
                  <Card className={styles.card}>
                    <CardActionArea>
                      <CardMedia
                        className={styles.media}
                        image={secondPlaceImg}
                        title="2nd place"
                      />
                    </CardActionArea>

                    <CardContent>
                      <h1 className="text-center">Drugo mesto</h1>
                      <Typography
                        align="center"
                        variant="h3"
                      >
                        <b>15,000 RSD</b>
                      </Typography>
                    </CardContent>
                  </Card>
                </div>

                <div className="col-md-4 order-1 order-md-2">
                  <Card className={styles.card}>
                    <CardActionArea>
                      <CardMedia
                        className={styles.media}
                        image={firstPlaceImg}
                        title="1st place"
                      />
                    </CardActionArea>

                    <CardContent>
                      <h1 className="text-center">Prvo mesto</h1>
                      <Typography
                        align="center"
                        variant="h2"
                      >
                        <b>20,000 RSD</b>
                      </Typography>
                    </CardContent>
                  </Card>
                </div>

                <div className="col-md-4 order-3 order-md-3">
                  <Card className={styles.card}>
                    <CardActionArea>
                      <CardMedia
                        className={styles.media}
                        image={thirdPlaceImg}
                        title="3rd place"
                      />
                    </CardActionArea>

                    <CardContent>
                      <h1 className="text-center">Treće mesto</h1>
                      <Typography
                        align="center"
                        variant="h3"
                      >
                        <b>10,000 RSD</b>
                      </Typography>
                    </CardContent>
                  </Card>
                </div>
              </div>

              <h1>FAQ</h1>

              <p>
                Odgovori na najčešće postavljena pitanja. Biće regularno ažurirana u odnosu na količinu postavljenih:
              </p>

              <div className="row">
                {toTheMoonFAQ.map((faq) => (
                  <div className="col-lg-4 col-md-6 mb-3" key={faq.id}>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`panel${faq.id}-content`}
                        id={`panel${faq.id}-header`}
                      >
                        <h3>{faq.question}</h3>
                      </AccordionSummary>

                      <AccordionDetails>
                        <Typography>{faq.answer}</Typography>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                ))}
              </div>

              <h1>
                Sponzori
              </h1>

              <p>
                Sponzori ovog takmičenja su <a href="https://www.joberty.rs">Joberty</a> i <a
                  href="https://www.netokracija.rs"
                >Netokracija</a>.
              </p>

              <div className="row row-eq-height">
                <div className="col-md-4">
                  <PartnerCard
                    title={partners.joberty.title}
                    description={partners.joberty.description}
                    content={partners.joberty.content}
                    buttonLink={partners.joberty.buttonLink}
                    imageURL={partners.joberty.imageURL}
                    link={partners.joberty.link}
                  />
                </div>

                <div className="col-md-4">
                  <PartnerCard
                    title={partners.netokracija.title}
                    description={partners.netokracija.description}
                    content={partners.netokracija.content}
                    buttonLink={partners.netokracija.buttonLink}
                    imageURL={partners.netokracija.imageURL}
                    link={partners.netokracija.link}
                  />
                </div>
              </div>

              <p>
                <b>Joberty</b> je platforma koja omogućava zaposlenima u IT kompanijama da podele svoja iskustva u radu
                kod poslodavaca, a kandidatima da pronađu posao koji im najviše odgovara. Preko njihove platforme možete
                saznati sve što vas interesuje o kompaniji za koju želite da aplicirate za posao, kao i ponudama i
                benefitima koje nude.
              </p>

              <p>Više o njima možete saznati u ovom videu:</p>

              <div className="row">
                <div className={cx('col-lg-6', 'py-3')}>
                  <iframe
                    title="ZoxJg7hVt7s"
                    width="100%"
                    height="350"
                    src="https://www.youtube.com/embed/ZoxJg7hVt7s?start=652"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  />
                </div>
              </div>

              <p>
                <b>Netokracija</b> je poslovno-tehnološki medij koji prati kulturu tehnološkog preduzetništva, digitalni
                marketing i nove tehnologije – na prostoru Srbije, Hrvatske i Slovenije. Njihov blog čita preko 80,000
                ljudi mesečno. Imaju veliki uticaj na domaću IT zajednicu i Netokracija je mesto koje daje uvid u globalne
                trendove i čiji kritički sadržaj formira javno mišljenje o primeni tehnologije – kako u javnom, tako i u
                privatnom sektoru.
              </p>

              <p>Možete pogledati moje gostovanje u ovom videu:</p>

              <div className="row">
                <div className={cx('col-lg-6', 'py-3')}>
                  <iframe
                    title="R_0t2c3Vl54"
                    width="100%"
                    height="350"
                    src="https://www.youtube.com/embed/R_0t2c3Vl54"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  />
                </div>
              </div>

              <h1>
                Medijski partneri
              </h1>

              <div className="row row-eq-height">
                {Object.keys(mediaPartners).map((key) => (
                  <div className="col-md-4" key={mediaPartners[key].title}>
                    <PartnerCard
                      title={mediaPartners[key].title}
                      content={mediaPartners[key].content}
                      buttonLink={mediaPartners[key].buttonLink}
                      imageURL={mediaPartners[key].imageURL}
                      link={mediaPartners[key].link}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default FirstToTheMoon2021;
