import LandingPage from './containers/Landing/LandingPage/LandingPage';
import AboutPage from './containers/About/AboutPage';
import ProjectPage from './containers/Projects/ProjectsPage';
import LoginPage from './containers/Login/LoginPage';
import WorkExperiencePage from './containers/WorkExperience/WorkExperiencePage';
import ContactPage from './containers/Contact/ContactPage';
import ContestsPage from './containers/Contests/ContestsPage';
import BlogPage from './containers/Blog/BlogPage';
import IstanbulNYE2020 from './containers/Blog/Posts/IstanbulNYE2020';
import NotFoundPage from './containers/NotFound/NotFoundPage/NotFoundPage';
import PartnersPage from './containers/Partners/PartnersPage';
import FirstToTheMoon2021 from './containers/Contests/FirstToTheMoon2021/FirstToTheMoon2021';

const routes = {
  landing: {
    path: '/',
    key: 'Landing-Page',
    name: 'Home',
    exact: true,
    component: LandingPage,
    routes: {},
    public: true,
  },
  about: {
    path: '/about',
    key: 'About-Page',
    name: 'About Me',
    exact: true,
    component: AboutPage,
    routes: {},
    public: true,
  },
  workExperience: {
    path: '/work-experience',
    key: 'Work-Experience-Page',
    name: 'Work Experience',
    exact: true,
    component: WorkExperiencePage,
    routes: {},
    public: true,
  },
  projects: {
    path: '/projects',
    key: 'Projects-Page',
    name: 'Projects',
    exact: true,
    component: ProjectPage,
    routes: {},
    public: true,
  },
  blog: {
    path: '/blog',
    key: 'Blog-Page',
    name: 'Blog',
    exact: true,
    component: BlogPage,
    routes: {},
    public: true,
  },
  blogIstanbul: {
    path: '/blog/istanbul-new-year-2020',
    key: 'Blog-Istanbul-New-Year-2020-Page',
    name: 'Istanbul-New-Year-2020',
    exact: true,
    component: IstanbulNYE2020,
    routes: {},
    public: true,
  },
  partners: {
    path: '/partners',
    key: 'Partners-Page',
    name: 'Partners',
    exact: true,
    component: PartnersPage,
    routes: {},
    public: true,
  },
  contact: {
    path: '/contact',
    key: 'Contact-Page',
    name: 'Contact',
    exact: true,
    component: ContactPage,
    routes: {},
    public: true,
  },
  contests: {
    path: '/contests',
    key: 'Contests-Page',
    name: 'Contests',
    exact: true,
    component: ContestsPage,
    routes: {},
    public: true,
  },
  contest: {
    path: '/contests/first-to-the-moon-2021',
    key: 'First-To-The-Moon-2021',
    name: 'Contest',
    exact: true,
    component: FirstToTheMoon2021,
    routes: {},
    public: true,
  },
  login: {
    path: '/login',
    key: 'Login-Page',
    name: 'Login',
    exact: true,
    component: LoginPage,
    routes: {},
    public: true,
  },
  notFound: {
    path: '*',
    key: 'Not-Found-Page',
    name: 'Not-Found-Page',
    exact: false,
    component: NotFoundPage,
    routes: {},
    public: true,
  },
};

export default routes;
