import React, { useEffect, useState } from 'react';
import axios from 'axios';
import moment from 'moment';

import classnames from 'classnames';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import Link from '@material-ui/core/Link';
import classes from './ProjectsPage.module.scss';
import Spinner from '../../components/Spinner/Spinner';
import HeaderBanner from '../../components/Banner/HeaderBanner';

function ProjectPage() {
  const GITHUB_URL = 'https://api.github.com/users/dzimiks/repos?sort=created&per_page=100';
  const [data, setData] = useState([]);

  useEffect(() => {
    axios.get(GITHUB_URL)
      .then((result) => {
        const newData = [];

        result.data.forEach((response) => {
          const {
            // eslint-disable-next-line camelcase
            html_url,
            // eslint-disable-next-line camelcase
            created_at,
            description,
            name,
            language,
          } = response;

          newData.push({
            name,
            createdAt: created_at,
            language,
            description,
            url: html_url,
          });
        });

        setData(newData);
      });
  }, []);

  return (
    <>
      <HeaderBanner title="Projects" />

      <div className={classnames(classes.gutter, 'container')}>
        <div className="row">
          <div className="col-12">
            <p>You can found all my projects at my <Link href="https://github.com/dzimiks">Github</Link> profile.</p>
            <p>Total Repos: <b>{data.length}</b></p>
          </div>
        </div>

        {data.length === 0 ? <Spinner /> : null}

        <div className="row row-eq-height">
          {data.map((result) => (
            <div key={result.url} className="col-md-4 col-12">
              <Card className={classes.card}>
                <div className={classnames(classes['card-title'], 'text-center')}>
                  <Typography variant="h4">
                    {result.name}
                  </Typography>
                </div>

                <CardContent>
                  <Typography gutterBottom variant="body2" color="textSecondary" component="p">
                    <b>Created at:</b> {moment(result.createdAt).format('DD.MM.YYYY')}
                  </Typography>

                  <Chip size="small" label={result.language || 'None'} color="secondary" />

                  <p>{result.description || 'Doesn\'t have a description.'}</p>

                  <Button
                    href={result.url}
                    variant="contained"
                    color="primary"
                  >
                    See more
                  </Button>
                </CardContent>
              </Card>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default ProjectPage;
