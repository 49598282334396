import React from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames/bind';
import style from './Banner.module.scss';

const cx = classnames.bind(style);

function Banner({ children, height, primary }) {
  return (
    <div
      className={cx('banner', 'centered', { 'banner--primary': primary })}
      style={{ height }}
    >
      {children}
    </div>
  );
}

Banner.propTypes = {
  children: PropTypes.node.isRequired,
  height: PropTypes.string,
  primary: PropTypes.bool,
};

Banner.defaultProps = {
  height: '300px',
  primary: false,
};

export default Banner;
