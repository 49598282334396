import React, { useState } from 'react';
import { Switch, BrowserRouter as Router } from 'react-router-dom';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
import routes from './routes';

import RouteWithSubRoutes from './components/RouteWithSubRoutes/RouteWithSubRoutes';
import PageLayout from './containers/Layout/PageLayout';

// Google Analytics init
ReactGA.initialize('UA-139601123-1');
ReactGA.pageview(window.location.pathname + window.location.search);

// Facebook Pixel init
ReactPixel.init('206300203893309');
ReactPixel.pageView();

function App() {
  const routeKeys = Object.keys(routes);
  const currentTheme = JSON.parse(localStorage.getItem('theme')) || false;

  const [themeMode, setThemeMode] = useState(currentTheme);

  const theme = React.useMemo(() => createMuiTheme({
    palette: {
      type: themeMode ? 'dark' : 'light',
      primary: {
        // light: will be calculated from palette.primary.main,
        main: '#ff8f00',
        // dark: will be calculated from palette.primary.main,
        // contrastText: will be calculated to contrast with palette.primary.main
      },
      secondary: {
        main: '#0070FF',
      },
    },
  }), [themeMode]);

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <PageLayout themeMode={themeMode} setThemeMode={setThemeMode}>
          <Switch>
            {routeKeys.map((routeKey) => (
              <RouteWithSubRoutes {...routes[routeKey]} />
            ))}
          </Switch>
        </PageLayout>
      </Router>
    </ThemeProvider>
  );
}

export default App;
