import React from 'react';
import classnames from 'classnames/bind';

import HeaderBanner from '../../components/Banner/HeaderBanner';

import styles from './contestsPage.module.scss';
import { programmingContests } from '../../utils';
import MediaCard from '../../components/Cards/MediaCard';

const cx = classnames.bind(styles);

function ContestsPage() {
  return (
    <>
      <HeaderBanner title="🚀 TO THE MOON - prvo online individualno srpsko takmičenje u programiranju" />

      <div className={cx('container', 'gutter')}>
        <div className="row">
          <div className="col-12">
            <h1>O takmičenju</h1>
            <p>
              U martu 2021. godine pokrenuo sam drugačiji vid takmičenja u programiranju u Srbiji.
              Ovo je nešto što me je oduvek interesovalo i uvek sam voleo da učestvujem u sličnim takmičenjima. Takođe
              me je interesovalo i kako izgleda biti sa druge strane kao organizator, a ne takmičar.
              Pre svega, želim da pokrenem mlade ljude u Srbiji da naprave koristan program ili proizvod koji im može
              doneti mnogo toga, od posla, znanja, iskustva, pa i do novca, naravno. Cilj mi je da se ljudi dobro zabave
              radeći nešto korisno!
              Nadam se da će se učesnicima dopasti ova inicijativa. Možda iz ovoga proistekne i neki ozbiljniji projekat
              na kome će neko raditi i nakon takmičenja. Sve u svemu, svima želim da se oprobaju, biće vrlo interesantno
              i nadam se da će svako pokazati svoju kreativnost na najvišem nivou.
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <h1>Prethodna takmičenja</h1>
            {programmingContests.map((contest) => (
              <div className="col-md-4 col-12" key={contest.title}>
                <MediaCard
                  imageURL={contest.imageURL}
                  title={contest.title}
                  date={contest.date}
                  description={contest.description}
                  link={contest.link}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default ContestsPage;
