export const messageTypes = {
  MESSAGE_DEFAULT_ERROR: 'Something went wrong! Please, try to reload a page!',
  MESSAGE_BAD_REQUEST: 'Sorry, something went wrong! Please, try to reload a page!',
  MESSAGE_UNAUTHORIZED: 'The request has not been applied because it lacks valid authentication credentials for the target resource.',
  MESSAGE_FORBIDDEN: 'You don\'t have permission to access on this server!',
  MESSAGE_CONFLICT: 'The request could not be completed due to a conflict with the current state of the target resource.',
  MESSAGE_SERVER_ERROR: 'There is some problem with a server! Please, try to reload a page!',
  MESSAGE_USER_REGISTER_SUCCESS: 'Your account has been registered successfully!',
  MESSAGE_ADD_CART_ITEM: 'The selected item has been added to the shopping cart!',
  MESSAGE_UPDATE_CART_ITEM: 'The selected item has been updated from the shopping cart!',
  MESSAGE_CART_ITEM_ALREADY_EXISTS: 'The selected item already exists in the shopping cart!',
  MESSAGE_UPDATE_ACCOUNT_SUCCESS: 'Your account has been updated successfully!',
  MESSAGE_LOGIN_ACCOUNT_SUCCESS: 'Your account has been logged in successfully!',
  MESSAGE_LOGOUT_SUCCESS: 'Your account has been logged out successfully!',
  MESSAGE_USER_ALREADY_LOGGED_IN: 'The user is already logged in!',
  MESSAGE_CART_QUANTITY_NOT_SELECTED: 'You have to select the quantity!',
  MESSAGE_USER_EMAIL_ALREADY_EXIST: 'User with same email already exist',
  MESSAGE_NO_ORDERS: 'No orders yet! :( Feel free to browse our categories page to fill this list ASAP!',
  MESSAGE_SESSION_EXPIRED: 'Your session has been expired! Please, try to log in again!',
  MESSAGE_LOGIN_FAILURE: 'There is some problem with login!',
};

export const notificationTypes = {
  NOTIFICATION_SUCCESS: 'success',
  NOTIFICATION_ERROR: 'error',
  NOTIFICATION_WARNING: 'warning',
  NOTIFICATION_INFO: 'info',
};

export const comparisonTypes = {
  GTE: 'GTE',
  GT: 'GT',
  LTE: 'LTE',
  LT: 'LT',
  EQ: 'EQ',
};

export const regexTypes = {
  /**
   * Matches any digit [0-9] exactly 16 times.
   */
  REGEX_CREDIT_CARD_NUMBER: /^[0-9]{16}$/,

  /**
   * Matches any English letter and whitespace character between one and unlimited times.
   */
  REGEX_ALPHA_AND_WHITESPACE_ONLY: /^[a-zA-z\s]+$/g,

  /**
   * Matches any whitespace character (equal to [\r\n\t\f\v ]).
   */
  REGEX_WHITESPACE_CHARACTER: /\s/g,

  /**
   * Matches a digit (equal to [0-9]) between one and unlimited times.
   */
  REGEX_NUMERIC_ONLY: /^\d+$/,

  /**
   * Matches any non-word character (equal to [^a-zA-Z0-9_]).
   */
  REGEX_NON_WORD_ONLY: /\W/gi,

  /**
   * Matches any character (except for line terminators) exactly 4 times.
   */
  REGEX_ANY_FOUR_CONSECUTIVE: /(.{4})/g,

  /**
   * Matches the characters %2C literally (2C maps to ,).
   */
  REGEX_URL_COMMA: /%2C/g,
};

export const errorTypes = {
  ERROR_LOGIN: 'ERROR_LOGIN',
  ERROR_SERVER_LOGIN: 'ERROR_SERVER_LOGIN',
  ERROR_CREATE_ACCOUNT: 'ERROR_CREATE_ACCOUNT',
  ERROR_EMAIL_CHECK: 'ERROR_EMAIL_CHECK',
};
