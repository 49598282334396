import React from 'react';
import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';
import classes from './Skillbar.module.scss';

function Skillbar(props) {
  const { size, skills } = props;

  return (
    <div className={classes.skillbar} data-percent={`${size}%`}>
      <Typography
        className={classes['skillbar-percent']}
        variant="body2"
        color="textPrimary"
        component="p"
        gutterBottom
      >
        {skills}
      </Typography>
      <div className={classes.skillwrap}>
        <div className={classes['skillbar-bar']} style={{ width: `${size}%` }} />
      </div>
    </div>
  );
}

Skillbar.propTypes = {
  size: PropTypes.string.isRequired,
  skills: PropTypes.string.isRequired,
};

export default Skillbar;
