import React from 'react';
import classnames from 'classnames/bind';
import Link from '@material-ui/core/Link';
import styles from './aboutPage.module.scss';

import dzimiksImage from '../../assets/images/dzimiks.png';
import HeaderBanner from '../../components/Banner/HeaderBanner';

const cx = classnames.bind(styles);

function AboutPage() {
  return (
    <>
      <HeaderBanner title="About" />

      <section className="section pt-5">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <h2>General</h2>

              <p>
                Hi, my name is Vanja!
              </p>

              <p>
                I'm a software engineer with 4+ years of diverse, professional experience. I'm always striving to make
                things faster, simpler, and more user and developer-friendly.
              </p>

              <p>
                UI development is the most enjoyable part of my career. I always strive to write well-designed,
                performant, fully tested code that works across multiple browsers. For the past couple of years, I have
                been focused on React and Redux development but I am well-versed in many other libraries/frameworks.
              </p>

              <p>
                More recently, I'm interested in blockchain development and the whole of its ideology. Right now, I'm
                working on a tool for real-time monitoring, alerting, and troubleshooting for Smart Contracts.
              </p>

              <p>
                Despite enjoying the front end, I tend to float toward whatever project needs the most attention, taking
                ownership and ensuring success, regardless of comfort level. I regularly develop on OSX and deploy
                across a variety of platforms. This has required close collaboration with a multitude of stakeholders,
                which includes security engineers, technical architects, product owners, managers, and designers from
                all around the world.
              </p>

              <p>
                The end result is a wide variety of experiences, from REST-based microservice development to writing
                test automation frameworks, large database migrations, authentication and authorization, and more.
              </p>

              <p>
                I do not mind jumping in and getting my hands dirty in existing projects. I thrive under pressure and
                love fast-paced companies that deliver compelling products.
              </p>

              <h2>History</h2>

              <p>
                At the end of my third year of high school, I decided to dedicate more time to finding a job I wanted to
                pursue in life. Since I was quite versatile, there were three items on the list, each different from the
                other: political science, programming, or sports journalism. At that time, I was in the language
                department at the XIV Belgrade Gymnasium in Vračar, and behind me I had competitions in strictly
                social sciences: the Serbian language, history, and even recitation.
              </p>

              <p>
                As usual, I did a lot of research - I wanted to examine in detail all the possibilities in our country
                related to these activities and, by chance, I came across a blog of a guy who was an intern at Microsoft
                and Facebook, graduated from the Faculty of Computer Science with a flawless GPA of 10/10 and went to
                work for Instagram in Silicon Valley. His name
                is <Link href="https://www.bozidarevic.com">Nenad Božidarević</Link>. On the same day, I contacted him
                where I literally said that I was an absolute beginner, that I didn't know which faculty to enroll in
                and that I didn't know where to start studying.
              </p>

              <p>
                After exchanging a few messages, I realized that programming is something in which I could show the most
                creativity and that I could learn to think differently. So I started learning the Java programming
                language with the basics of the web: HTML, CSS and JavaScript. The first program printed text in a for
                loop, while later I spent much more time on online courses and platforms for learning programming, such
                as Codecademy and W3Schools.
              </p>
            </div>
            <div className={cx('col-md-4', 'text-center', 'drag-lg-top')}>
              <div className={cx('mb-4')}>
                <img
                  src={dzimiksImage}
                  alt="dzimiks"
                  className={cx('img-fluid', 'w-100', 'rounded-lg', 'border-danger')}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default AboutPage;
