import { v4 as uuidv4 } from 'uuid';
import { toast } from 'react-toastify';
import { messageTypes } from '../consts';

const {
  MESSAGE_DEFAULT_ERROR,
  MESSAGE_SERVER_ERROR,
  MESSAGE_UNAUTHORIZED,
  MESSAGE_FORBIDDEN,
  MESSAGE_CONFLICT,
  MESSAGE_BAD_REQUEST,
} = messageTypes;

const showNotification = ({
  type,
  message,
  status = 200,
  position = toast.POSITION.TOP_RIGHT,
  autoClose = 3000,
}) => {
  let finalMessage = message || MESSAGE_DEFAULT_ERROR;

  if (status === 400) {
    finalMessage = MESSAGE_BAD_REQUEST;
  } else if (status === 401) {
    finalMessage = MESSAGE_UNAUTHORIZED;
  } else if (status === 403) {
    finalMessage = MESSAGE_FORBIDDEN;
  } else if (status === 409) {
    finalMessage = MESSAGE_CONFLICT;
  } else if (status === 500) {
    finalMessage = MESSAGE_SERVER_ERROR;
  }

  const notificationOptions = {
    position,
    autoClose,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    toastId: uuidv4(),
  };

  toast[type](finalMessage, notificationOptions);
};

export default showNotification;
