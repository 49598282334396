import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classnames from 'classnames';

import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CssBaseline from '@material-ui/core/CssBaseline';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';

import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import IconButton from '@material-ui/core/IconButton';
import HomeIcon from '@material-ui/icons/Home';
import InfoIcon from '@material-ui/icons/Info';
import WorkIcon from '@material-ui/icons/Work';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import CreateIcon from '@material-ui/icons/Create';
import EmailIcon from '@material-ui/icons/Email';
import HelpIcon from '@material-ui/icons/Help';
import PeopleIcon from '@material-ui/icons/People';
import Brightness4Icon from '@material-ui/icons/Brightness4';
import Brightness7Icon from '@material-ui/icons/Brightness7';
import CodeIcon from '@material-ui/icons/Code';

import logo from '../../assets/images/dzimiks_logo.png';

import routes from '../../routes';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    color: '#fefefe',
    background: 'rgba(4, 15, 30, 0.85)',
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
}));

function Header(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const { themeMode, setThemeMode } = props;
  const routeKeys = Object.keys(routes);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleSwitchTheme = () => {
    const currentTheme = !themeMode;
    setThemeMode(currentTheme);
    localStorage.setItem('theme', JSON.stringify(currentTheme));
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={classnames(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar className={classes.header}>
          <Link to="/">
            <img src={logo} alt="dzimiks" width={30} />
          </Link>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="end"
            onClick={handleDrawerOpen}
            className={classnames(open && classes.hide)}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="temporary"
        anchor="right"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
        onEscapeKeyDown={handleDrawerClose}
        onBackdropClick={handleDrawerClose}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
          <Typography variant="h5">
            dzimiks
          </Typography>
        </div>
        <Divider />
        <List>
          {routeKeys
            .filter((routeKey) => routes[routeKey].name !== 'Istanbul-New-Year-2020'
              && routes[routeKey].name !== 'Login'
              && routes[routeKey].name !== 'Not-Found-Page'
              && routes[routeKey].name !== 'First-To-The-Moon-2021')
            .map((routeKey) => {
              const { key, name, path } = routes[routeKey];
              let icon;

              switch (name) {
                case 'Home':
                  icon = <HomeIcon />;
                  break;
                case 'About Me':
                  icon = <InfoIcon />;
                  break;
                case 'Work Experience':
                  icon = <WorkIcon />;
                  break;
                case 'Projects':
                  icon = <AccountTreeIcon />;
                  break;
                case 'Blog':
                  icon = <CreateIcon />;
                  break;
                case 'Partners':
                  icon = <PeopleIcon />;
                  break;
                case 'Contact':
                  icon = <EmailIcon />;
                  break;
                case 'Contests':
                  icon = <CodeIcon />;
                  break;
                default:
                  icon = <HelpIcon />;
                  break;
              }

              return (
                <ListItem
                  button
                  key={key}
                  component={Link}
                  to={path}
                  onClick={handleDrawerClose}
                >
                  <ListItemIcon>{icon}</ListItemIcon>
                  <ListItemText primary={name} />
                </ListItem>
              );
            })}
          <ListItem
            button
            onClick={handleSwitchTheme}
          >
            <ListItemIcon>{themeMode ? <Brightness4Icon /> : <Brightness7Icon />}</ListItemIcon>
            <ListItemText primary={themeMode ? 'Light mode' : 'Dark mode'} />
          </ListItem>
        </List>
      </Drawer>
    </div>
  );
}

Header.propTypes = {
  themeMode: PropTypes.bool.isRequired,
  setThemeMode: PropTypes.func.isRequired,
};

export default Header;
